@charset "UTF-8";
/**
 * @license
 * MyFonts Webfont Build ID 3274127, 2016-08-24T10:31:13-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: ClarendonBT-Light by Bitstream
 * URL: http://www.myfonts.com/fonts/bitstream/clarendon/light/
 * Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 * Licensed pageviews: 10,000
 *
 * Webfont: PlantinMTStd-Bold by Monotype
 * URL: http://www.myfonts.com/fonts/mti/plantin/std-bold/
 * Copyright: Font software Copyright 1990, 1995, 2002 Adobe Systems Incorporated. All rights reserved. Typeface designs Copyright The Monotype Corporation. All rights reserved.
 * Licensed pageviews: 250,000
 *
 * Webfont: PlantinMTStd-BoldItalic by Monotype
 * URL: http://www.myfonts.com/fonts/mti/plantin/std-bold-italic/
 * Copyright: Font software Copyright 1990, 1995, 2002 Adobe Systems Incorporated. All rights reserved. Typeface designs Copyright The Monotype Corporation. All rights reserved.
 * Licensed pageviews: 250,000
 *
 * Webfont: PlantinMTStd-Italic by Monotype
 * URL: http://www.myfonts.com/fonts/mti/plantin/std-italic/
 * Copyright: Font software Copyright 1990, 1995, 2002 Adobe Systems Incorporated. All rights reserved. Typeface designs Copyright The Monotype Corporation. All rights reserved.
 * Licensed pageviews: 250,000
 *
 * Webfont: PlantinMTStd-Regular by Monotype
 * URL: http://www.myfonts.com/fonts/mti/plantin/std-roman/
 * Copyright: Font software Copyright 1990, 1995, 2002 Adobe Systems Incorporated. All rights reserved. Typeface designs Copyright The Monotype Corporation. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3274127
 *
 * © 2016 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work (DP: not true - it's a usage counter - moved! */
/*
@import url("//hello.myfonts.net/count/31f58f");
I've moved this to the page footer to avoid blocking in this stylesheet
*/
@font-face {
  font-family: "ClarendonBT-Light";
  font-display: swap;
  src: local("ClarendonBT-Light"), url("../fonts/31F58F_0_0.woff2") format("woff2"), url("../fonts/31F58F_0_0.woff") format("woff"), url("../fonts/31F58F_0_0.ttf") format("truetype"), url("../fonts/31F58F_0_0.eot?#iefix") format("embedded-opentype");
  unicode-range: U+000-5FF;
  /* Latin glyphs */
}
@font-face {
  font-family: "PlantinMTStd-Bold";
  font-display: swap;
  src: local("PlantinMTStd-Bold"), url("../fonts/31F58F_1_0.woff2") format("woff2"), url("../fonts/31F58F_1_0.woff") format("woff"), url("../fonts/31F58F_1_0.ttf") format("truetype"), url("../fonts/31F58F_1_0.eot?#iefix") format("embedded-opentype");
  unicode-range: U+000-5FF;
  /* Latin glyphs */
}
@font-face {
  font-family: "PlantinMTStd-Regular";
  font-display: swap;
  src: local("PlantinMTStd-Regular"), url("../fonts/31F58F_4_0.woff2") format("woff2"), url("../fonts/31F58F_4_0.woff") format("woff"), url("../fonts/31F58F_4_0.ttf") format("truetype"), url("../fonts/31F58F_4_0.eot?#iefix") format("embedded-opentype");
  unicode-range: U+000-5FF;
  /* Latin glyphs */
}
@font-face {
  font-family: "swoopicon";
  src: url("../fonts/swoop-icon.eot?hduwh9");
  src: url("../fonts/swoop-icon.eot?hduwh9#iefix") format("embedded-opentype"), url("../fonts/swoop-icon.ttf?hduwh9") format("truetype"), url("../fonts/swoop-icon.woff?hduwh9") format("woff"), url("../fonts/swoop-icon.svg?hduwh9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_variables.scss - SASS VARIABLES AND MIXINS
------------------------------------------------------------------------------------------------
  Created:  01-03-2015
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/
/* Swoop Colours */
/* Breakpoints */
/* Mixins */
/* Reset all LIs that aren't in body content */
/* Override the bourbon mixin as ie8 doesn't support :: */
/* Add icon to element */
/* Hide text within icon link */
/* Switch Font smoothing settings */
/* Screen reader only text */
/* Column width */
/* Text Sizes */
/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_forms.scss - FORM ELEMENTS BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  01-03-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/
/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_variables.scss - SASS VARIABLES AND MIXINS
------------------------------------------------------------------------------------------------
  Created:  01-03-2015
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/
/* Swoop Colours */
/* Breakpoints */
/* Mixins */
/* Reset all LIs that aren't in body content */
/* Override the bourbon mixin as ie8 doesn't support :: */
/* Add icon to element */
/* Hide text within icon link */
/* Switch Font smoothing settings */
/* Screen reader only text */
/* Column width */
/* Text Sizes */
/*
---------------------------------------------------------------------------------- FORM ELEMENTS

------------------------------------------------------------------------------------------------
*/
.form-group {
  clear: both;
  margin-bottom: 15px;
}
.form-group:after {
  clear: both;
  content: "";
  display: table;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

label {
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  margin-bottom: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
.btn {
  box-sizing: border-box;
}

.form-control {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  padding: 4px 17px;
  margin: 0;
  border: 1px solid #cecfcf;
  color: #454545;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 1.6;
  width: 100%;
  height: 44px;
  outline: none;
  border-radius: 0;
  transition: all 0.3s ease-out;
  border-radius: 0;
}
.form-control:focus {
  border-color: #e66c28;
  background-color: #fafafc;
}
.form-control:focus::placeholder {
  color: #b9b9b9;
}

.form-control.form-control-xs {
  font-size: 14px;
  line-height: 1.7857;
  height: 27px;
}

.form-control-static {
  margin-bottom: 0;
  padding: 4px 0 0 0;
  font-size: 1.063em;
  line-height: 1.6;
}

textarea.form-control {
  height: auto;
}

/*.form-control::-webkit-input-placeholder {color: $text-placeholder;}
.form-control:-moz-placeholder {color: $text-placeholder;}
.form-control::-moz-placeholder {color: $text-placeholder;}
.form-control:-ms-input-placeholder {color: $text-placeholder;}*/
.input-group .form-control {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > button {
  margin-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.table-input {
  margin: 0;
  max-width: 80px;
  text-align: right;
}

input.input-4-digits {
  max-width: 80px;
}

.form-group-sm label {
  padding-top: 3px;
  font-size: 1.4em;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 0.875em;
  line-height: 1.5;
}

input[readonly]:focus {
  border-color: #ffffff;
  background-color: #ffffff;
}

textarea {
  margin: 0;
  vertical-align: top;
}

input[type=radio] {
  margin-right: 10px;
}

input[type=checkbox],
input[type=radio] {
  padding: 0 0 0 0;
  margin: -2px 6px 0 0;
  height: 16px;
  cursor: pointer;
}

select.form-control {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  -ms-appearance: menulist;
  -o-appearance: menulist;
  appearance: menulist;
}

select[multiple],
select[size] {
  height: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.checkbox label,
.radio label {
  min-height: 20px;
  margin-bottom: 0;
  padding-top: 0;
  font-weight: 400;
  cursor: pointer;
  float: none;
}

button,
.btn {
  text-transform: uppercase;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0.6em 1.4em;
  margin: 0 20px 22px 0;
  background-color: #e66c28;
  border: 1px solid #e66c28;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  transition: background-color 100ms linear, color 100ms linear, border-color 100ms linear;
  text-decoration: none;
  border-radius: 4px;
  position: relative;
}

button:hover,
.btn:hover,
.btn:focus {
  background-color: #c35112;
  border-color: #c35112;
  text-decoration: none;
}

.btn-disabled,
.btn-disabled:hover {
  cursor: not-allowed;
  pointer-events: none;
}

.btn-full {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  float: none;
  text-align: center;
  margin-right: 0;
}
@media screen and (max-width: 500px) {
  .btn-full {
    width: auto;
  }
}

.btn-lrg {
  line-height: 2.33333333;
}

.btn-secondary {
  background-color: transparent;
  border-color: #272727;
  color: #e66c28;
  padding: 10px 20px;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #e66c28;
  border-color: #974213;
  color: #fff;
}

.bg-swoop .btn-secondary {
  border-color: #ffffff;
}

.btn-tertiary {
  background-color: transparent;
  border-color: #272727;
  color: #272727;
}

.btn-tertiary:hover {
  color: #ffffff;
}

.btn-anchor {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #272727;
  font-size: 15px;
  line-height: 1.1;
  padding: 15px 10px;
}

@media screen and (max-width: 719px) {
  .btn-anchor {
    font-size: 13px;
    padding: 5px 10px;
    margin-bottom: 0;
    line-height: unset;
  }

  .anchor-row {
    padding-bottom: 0;
  }
  .anchor-row li {
    display: inline-block;
    margin: 0 4px;
  }

  .anchor-row-wrapper {
    padding: 7px 0;
    overflow-y: scroll;
    min-width: 100vw;
  }
  .anchor-row-wrapper div.row {
    width: max-content;
    min-width: 100vw;
  }
}
.btn-anchor:hover,
.btn-anchor:focus {
  background-color: #edb594;
  border-color: #edb594;
}

.btn-text {
  padding: 0 20px 0 0;
  position: relative;
  text-align: center;
  background-color: transparent;
  border: none;
  color: #e66c28;
}

.btn-text:after {
  position: absolute;
  transition: right 0.1s ease-out;
}

.btn-text:hover,
.btn-text:focus {
  background-color: transparent;
  border: none;
  color: #edb594;
}

.btn-down:after,
.btn-up:after {
  position: absolute;
  content: "▼";
  font-size: 1rem;
  top: -1px;
  right: -1px;
}

.btn-up:after {
  content: "▲";
}

table .btn-text:after {
  bottom: 8px;
}

.btn-text:hover:after {
  right: 0;
}

.btn-link {
  border-color: transparent;
  background-color: transparent;
  color: #e66c28;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  border-color: transparent;
  background-color: transparent;
  color: #e66c28;
}

.btn-sm {
  font-size: 16px;
  line-height: 1.625;
}

.btn-xs {
  font-size: 13px;
  line-height: 1.4;
  padding: 4px 12px;
}
@media screen and (min-width: 30em) {
  .btn-xs {
    font-size: 14px;
  }
}

.btn-inner {
  color: white;
}

.btn-inner:hover {
  text-decoration: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.btn {
  transition: background-color 100ms linear, color 100ms linear, border-color 100ms linear, padding 200ms ease-in-out;
}

.btn-pending {
  padding-right: 42px;
  position: relative;
}

.btn-full.btn-pending {
  padding-left: 42px;
}

.btn-pending:after {
  content: "";
  display: block;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 12px;
  right: 10px;
  background: url("../img/ajax-loader-btn.gif") 0 0 no-repeat;
  opacity: 0;
  transition: opacity 200ms linear;
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
  .btn-pending:after {
    background-image: url("../img/retina/ajax-loader-btn.gif");
    background-size: 19px 19px;
  }
}

.btn-primary.btn-pending:after {
  background-image: url("../img/ajax-loader-btn-primary.gif");
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
  .btn-primary.btn-pending:after {
    background-image: url("../img/retina/ajax-loader-btn-primary.gif");
  }
}

.btn-pending-show:after {
  opacity: 1;
}

.error label.error {
  color: #a20000;
  font-weight: 300;
  font-size: 16px;
}

.note {
  font-size: 1.06em;
}

.error .form-control {
  border-color: #a20000;
  border-width: 2px;
}

.valid .form-control {
  border-color: #5ab54a;
  border-width: 2px;
}

.form-container:after {
  clear: both;
  content: "";
  display: table;
}

.form-container-result {
  display: none;
}

.ajax-spinner {
  width: 19px;
  height: 19px;
  background: url("../img/ajax-loader.gif") 0 0 no-repeat;
  display: none;
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
  .ajax-spinner {
    background-image: url("../img/retina/ajax-loader.gif");
    background-size: 19px 19px;
  }
}

.ajax-spinner-inline {
  width: 19px;
  height: 10px;
  background: url("../img/ajax-loader-inline.gif") 0 0 no-repeat;
  display: none;
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
  .ajax-spinner-inline {
    background-image: url("../img/retina/ajax-loader-inline.gif");
    background-size: 16px 4px;
  }
}

.ajax-spinner.ajax-spinner-show {
  display: block;
}

.ajax-spinner-inline.ajax-spinner-show {
  display: inline-block;
}

/*
----------------------------------------------------------------------------------------- LAYOUT

------------------------------------------------------------------------------------------------
*/
@media screen and (min-width: 45em) {
  .form-horizontal label {
    width: 25%;
    float: left;
    padding-top: 6px;
    padding-right: 16px;
    text-align: right;
  }

  .form-horizontal .form-control {
    width: 75%;
  }

  .form-horizontal select.form-control {
    width: 100%;
  }

  .form-horizontal .select {
    width: 75%;
    float: right;
  }

  .form-horizontal .error-msg,
.form-horizontal .label-offset,
.form-horizontal label.error {
    padding-left: 25%;
    padding-right: 8px;
    width: 100%;
    text-align: left;
  }

  .form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 27px;
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
    width: 75%;
    margin-left: 25%;
  }

  .form-horizontal .checkbox label,
.form-horizontal .radio label {
    float: none;
    width: auto;
    padding-top: 0;
  }

  .form-horizontal .control-checkboxes,
.form-horizontal .control-radios {
    width: 65%;
    float: right;
  }

  .form-horizontal .form-submit {
    padding-left: 25%;
  }

  .form-horizontal .rating,
.form-horizontal .rating-static {
    padding-top: 7px;
  }

  .checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
  }
}
/*
---------------------------------------------------------------------- CHOSEN DROPDOWN OVERRIDES

------------------------------------------------------------------------------------------------
*/
.chosen-container {
  /*margin-bottom:10px;*/
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chosen-container a {
  font-weight: 300;
}

.chosen-container-single .chosen-single {
  background: #ffffff;
  color: #454545;
  font-weight: 300;
  border: 1px solid #cecfcf;
  overflow: visible;
  height: 44px;
  padding-left: 14px;
  border-radius: 0;
}

.chosen-container-multi .chosen-choices {
  background-color: #ffffff;
  color: #515151;
  border: 1px solid #cecfcf;
  height: 44px;
  font-size: 1em;
  padding-left: 14px;
  border-radius: 0;
}

.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  height: 44px;
}

.chosen-container-single .chosen-default {
  color: #a9a9a9;
}

.chosen-container-single .chosen-single:hover,
.chosen-container-active.chosen-with-drop .chosen-single {
  background-color: #fafafc;
}

.chosen-container-active.chosen-with-drop .chosen-single div:before {
  background-position: -204px -25px;
}

.chosen-container-active .chosen-single,
.chosen-container-active .chosen-choices {
  border: 1px solid #e66c28;
}

.chosen-container-single .chosen-single span {
  padding-top: 1px;
}

.chosen-container-single .chosen-single div {
  width: 35px;
  height: auto;
}

.chosen-container-single .chosen-single div:before {
  width: 16px;
  height: 11px;
  position: absolute;
  top: 17px;
  left: 9px;
  background-position: -204px 0;
}

.chosen-container-single .chosen-single div b {
  display: none;
}

.chosen-container-single .chosen-single abbr {
  width: 18px;
  height: 18px;
  top: 12px;
  right: 36px;
  background-position: -220px 4px;
  opacity: 0.5;
}

.chosen-container-single .chosen-single abbr:hover {
  background-position: -220px 4px;
  opacity: 1;
}

.chosen-container-multi .chosen-choices li.search-choice {
  margin-top: 5px;
  padding: 6px 20px 6px 8px;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  right: 6px;
  top: 5px;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before {
  width: 16px;
  height: 16px;
  top: 12px;
  right: 36px;
  background-position: -220px 4px;
  opacity: 0.5;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover:before {
  background-position: -220px 4px;
  opacity: 1;
}

.chosen-container .chosen-drop {
  background-color: #ffffff;
}

.chosen-container-active .chosen-drop {
  border: 1px solid #cecfcf;
}

.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
}

.chosen-container .chosen-results li.highlighted {
  background-color: #e66c28;
}

.error .chosen-container-single .chosen-single,
.error .chosen-container-multi .chosen-choices {
  border-color: #a20000;
  border-width: 2px;
}

.valid .chosen-container-single .chosen-single,
.valid .chosen-container-multi .chosen-choices {
  border-color: #5ab54a;
  border-width: 2px;
}

.error .chosen-container-single .chosen-single abbr,
.valid .chosen-container-single .chosen-single abbr {
  top: 9px;
}

.error .chosen-container-single .chosen-single div:before,
.valid .chosen-container-single .chosen-single div:before {
  top: 14px;
}

.error .chosen-container-single .chosen-single span,
.valid .chosen-container-single .chosen-single span {
  padding-top: 0;
}

/*  [SCS-1764] Input group */
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group-btn .btn {
  margin: 0;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-group-remember .checkbox-label {
  display: inline-block;
  margin: 0;
  padding-left: 0;
  padding-right: 33px;
  width: auto;
  font-size: 1em;
}
.form-group-remember .checkbox-label .checkmark {
  top: 0.4em;
  right: 0.5em;
  left: auto;
  height: 15px;
  width: 15px;
}
.form-group-remember .checkbox-label .checkmark::after {
  left: 5px;
  top: 1.5px;
  width: 4px;
  height: 9px;
}

/*
------------------------------------------------------------------------------- ENQUIRY /  FORMS

------------------------------------------------------------------------------------------------
*/
.cta {
  padding-top: 8px;
}

.enquiry-intro {
  max-width: 740px;
  margin: 0 auto;
  padding: 1rem 0 0;
}

.content-tel {
  font-size: 1.25em;
}

.content-tel a:hover {
  text-decoration: none;
}

.enquiry-form {
  max-width: 740px;
  margin: 0 auto;
  padding: 30px;
  background-color: #e8eaec;
}

.form-block {
  width: 100%;
}

.field {
  position: relative;
}

.field i {
  left: 2px;
  top: 4px;
  position: absolute;
  height: 32px;
  width: 32px;
  opacity: 0.7;
  text-align: center;
  line-height: 42px;
  pointer-events: none;
}

.enquiry-input__icon {
  margin-bottom: -0.6rem;
  padding: 1.9rem;
  position: relative;
  top: -38px;
  left: 3px;
  opacity: 0.5;
  display: inline-block;
}

.name-icon {
  background: url(/assets/front/app/img/svg-icon/user.svg);
  background-repeat: no-repeat;
}

.email-icon {
  background: url(/assets/front/app/img/svg-icon/email.svg);
  background-repeat: no-repeat;
}

.phone-icon {
  background: url(/assets/front/app/img/svg-icon/phone.svg);
  background-repeat: no-repeat;
}

.chevron-icon {
  background: url(/assets/front/app/img/svg-icon/chevron.svg);
  background-repeat: no-repeat;
}

#firstName.form-control,
#email.form-control,
#refer-tel.form-control,
#tel.form-control,
#friend.form-control {
  padding: 4px 17px 4px 35px;
}

.form-submit {
  padding-top: 12px;
}

.form-submit .btn {
  margin-bottom: 0;
}

.form-submit .btn:last-child {
  margin-right: 0;
}

.alert {
  background-color: #e8eaec;
  border: 1px solid #d1d5d9;
  padding: 12px 16px 0 16px;
  margin-bottom: 20px;
  position: relative;
}

.alert .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 6px 10px;
  font-size: 1.25em;
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #000;
}

.alert .btn-close:hover {
  color: #333;
}

.alert p {
  margin-bottom: 12px;
}

.alert .btn {
  margin-right: 10px;
}

.alert.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert h4 {
  margin-bottom: 12px;
}

.enquiry-form-wrapper h3 {
  padding-top: 24px;
  padding-bottom: 18px;
  border-top: 4px solid #272727;
  border-bottom: 1px solid #272727;
  margin-bottom: 36px;
}

.split-input div.form-group {
  width: 48%;
}

.js-enquiry-form button[type=submit]:disabled {
  background-color: rgb(113, 128, 150);
  border-color: #4a5568;
  cursor: not-allowed;
}

/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_tables.scss - TABLE ELEMENT BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  28-07-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/
/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_variables.scss - SASS VARIABLES AND MIXINS
------------------------------------------------------------------------------------------------
  Created:  01-03-2015
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/
/* Swoop Colours */
/* Breakpoints */
/* Mixins */
/* Reset all LIs that aren't in body content */
/* Override the bourbon mixin as ie8 doesn't support :: */
/* Add icon to element */
/* Hide text within icon link */
/* Switch Font smoothing settings */
/* Screen reader only text */
/* Column width */
/* Text Sizes */
/*
----------------------------------------------------------------------------------------- TABLES

------------------------------------------------------------------------------------------------
*/
.table-responsive-foot {
  padding: 24px 12px;
  font-size: 0.875em;
  line-height: 1.2;
  display: block;
  text-align: center;
}

.table-responsive {
  overflow: auto;
}

.table-responsive.table-scroll {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ccc;
}

table {
  width: 100% !important;
  clear: both;
}

.table tbody {
  border: 1px solid #272727;
}

.table-responsive {
  padding-right: 1px;
}

.table-responsive.table-scroll {
  padding-right: 0;
}

.table-responsive.table-scroll table tbody {
  border: none;
}

.table span {
  font-size: 1em;
  line-height: 1.2;
  padding: 5px 0;
  display: block;
}

.table div span {
  display: inline;
}

.table span span {
  font-size: 1em;
  line-height: 1.2;
  padding: 0;
  display: inline;
}

.table tr th {
  text-align: left;
  font-weight: 300;
}

.table .right {
  text-align: right;
}

.table p {
  margin-bottom: 0;
}

.table img {
  margin: 0;
}

table tr td {
  padding: 12px;
  vertical-align: top;
}

.table tr td {
  padding: 2px 12px 2px 12px;
}

.table tfoot tr {
  border-top: 1px solid #272727;
}

.table .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.table thead {
  display: none;
}

.table tr {
  display: block;
  border-bottom: 1px solid #d6d6d6;
}

.table td {
  display: block;
  text-align: right;
  border-bottom: 1px dotted #d6d6d6;
}

.table td:last-child {
  border-bottom: 0;
}

.table td:before {
  content: attr(data-label);
  float: left;
  font-size: 0.875em;
  line-height: 1.2em;
  padding: 5px 0;
  font-weight: 600;
}

.table tfoot td {
  text-align: left;
}

.table .cta {
  padding-top: 4px;
  padding-bottom: 4px;
}

.table .even td {
  border-color: #d6d6d6;
  background-color: #e8eaec;
}

.table .title td {
  background-color: #8c97a2;
  color: #ffffff;
}

.table .title td span {
  /*font-size: 2.8em;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table tr td.deal-badge-cell {
  padding-top: 7px;
  padding-bottom: 7px;
}

.table tr td.deal-badge-cell:empty {
  display: none;
}

.table tr.title td {
  text-align: left;
  padding-top: 7px;
  padding-bottom: 7px;
}

.table.table-no-mobile-labels td {
  text-align: left;
}

.table.table-no-mobile-labels td:before {
  display: none;
}

.table thead.sticky-header th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}

/* ---------------------- BREAK POINT */
@media screen and (min-width: 45em) {
  .table thead {
    display: table-header-group;
  }

  .table tr {
    display: table-row;
  }

  .table td {
    display: table-cell;
    text-align: left;
    border-bottom: none;
  }

  .table td:before {
    display: none;
  }

  .table tr {
    border-bottom: none;
  }

  table tr th {
    padding: 14px 14px;
  }

  table tr td,
.table tr td {
    padding: 16px 14px;
  }

  .table tr th:first-child, table tr td:first-child {
    padding-left: 12px;
  }

  .table tr th:last-child, table tr td:last-child {
    padding-right: 12px;
  }

  .table .cta {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table .col-date {
    width: 200px;
  }

  .table .col-cta {
    width: 250px;
  }

  .table .col-cta .cta {
    float: right;
  }

  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-height: 0.01%;
    overflow-x: auto;
    clear: both;
  }

  .table-responsive table {
    width: 100%;
    max-width: 100%;
  }

  .deal-badge,
.table .deal-badge {
    font-size: 1em !important;
    line-height: 1;
    display: block;
    padding-right: 12px !important;
  }

  .deal-badge span,
.table .deal-badge span,
.trips-table-cruise .table span.deal-badge span {
    font-size: 0.563em;
    display: block;
  }

  .table tr td.deal-badge-cell {
    width: 130px;
    padding-top: 10px;
    padding-bottom: 8px;
  }

  .table tr td.deal-badge-cell:empty {
    display: table-cell;
  }

  .table tr.title td {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .trips-table-cruise .table thead {
    display: table-header-group;
    background-color: #ffffff;
  }

  .trips-table-cruise .table thead th {
    height: 110px;
  }

  .trips-table-cruise .table thead div {
    transform: translate(10px, 40px) rotate(315deg);
    width: 80px;
    position: relative;
    bottom: 24px;
    right: 10px;
    font-weight: bold;
  }

  .trips-table-cruise .table span {
    font-size: 0.938;
  }

  .trips-table-cruise .table span span {
    font-size: 0.938;
  }

  .trips-table-cruise .table .title td span {
    font-size: 1em;
  }

  .trips-table-cruise .btn {
    font-size: 0.938;
    text-align: center;
  }

  .trips-table-cruise .table .btn-text:after {
    bottom: 5px;
  }

  .trips-table-cruise .col-date {
    width: 130px;
  }

  .trips-table-cruise .table td,
.trips-table-cruise .table .even td {
    border-right: 1px solid #8c97a2;
    background-clip: padding-box;
  }

  .trips-table-cruise .table td.deal-badge-cell,
.trips-table-cruise .table .even td.deal-badge-cell,
.trips-table-cruise .table td.col-cta,
.trips-table-cruise .table .even td.col-cta,
.trips-table-cruise .table .title td,
.trips-table-cruise .table .title.even td {
    border-right: none;
  }

  .trips-table-cruise .table .col-cta {
    width: 120px;
  }
}
@media screen and (min-width: 60em) {
  .table-responsive.table-scroll {
    max-height: 642px;
  }
}
/*
------------------------------------------------------------------------------------------------
  _site-pending-elements.scss - BUTTON ELEMENT BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  01-03-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/
.form-disable {
  position: relative;
}
.form-disable:after {
  clear: both;
  content: "";
  display: table;
}

.form-disable:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #ffffff;
  opacity: 0;
  z-index: 10;
  transition: opacity 150ms linear;
}

.form-disable.form-disable-dark:before {
  background-color: #000000;
}

.form-disable.disabled:before {
  bottom: 0;
  height: auto;
  opacity: 0.4;
}

.form-disable.disabled.fadeout:before {
  bottom: 0;
  height: auto;
  opacity: 0;
}

/*
.ajax-spinner {
  width:19px;
  height:19px;
  background: url('../img/ajax-loader.gif') 0 0 no-repeat;
  display:none;

  @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    background-image: url('../img/retina/ajax-loader.gif');
    background-size: 19px 19px;
  }

}

.ajax-spinner-inline {
  width:19px;
  height:10px;
  background: url('../img/ajax-loader-inline.gif') 0 0 no-repeat;
  display:none;

  @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    background-image: url('../img/retina/ajax-loader-inline.gif');
    background-size: 16px 4px;
  }

}

.ajax-spinner.ajax-spinner-show {display:block;}
.ajax-spinner-inline.ajax-spinner-show {display:inline-block;}

// CSS ONLY SPINNER

.css-spinner,
.css-spinner:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;

}
.css-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(50, 50, 50, 0.2);
  border-right: 3px solid rgba(50, 50, 50, 0.2);
  border-bottom: 3px solid rgba(50, 50, 50, 0.2);
  border-left: 3px solid $text-main;
  transform: translateZ(0);
  animation: spinnerAnim 0.5s infinite linear;
  opacity:1;
  transition: opacity 0.2s linear;
}

@keyframes spinnerAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-spinner.fade-out-spinner .css-spinner {opacity:0;}
.css-spinner.fade-out {opacity:0;}



// BUTTON SPINNER

.btn-pending {padding-right:42px; position:relative;}
.btn-full.btn-pending {padding-left:42px;} // default padding

.btn-pending:after {
    content:'';
    font-size: 10px;

    display:block;
    width:19px;
    height:19px;

    position:absolute;
    top:8px;
    right:10px;

    border-radius: 50%;

    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid $white;

    transform: translateZ(0);
    animation: spinnerAnim 0.5s infinite linear;
    opacity:0;
    transition: opacity 0.2s linear;
}

.btn-pending-show:after {opacity:1;}

.btn-sm.btn-pending:after {top:8px;}

*/
/*
--------------------------------------------------------------------------------- COOKIE WARNING

------------------------------------------------------------------------------------------------
*/
.cookie-warning-wrapper {
  z-index: 9001;
  position: relative;
}

@keyframes cookieSlideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.cookie-warning {
  font-size: 0.875em;
  padding: 12px 15px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #1d3455;
  background: rgba(29, 52, 85, 0.95);
  color: #ffffff;
  transform: translateY(100%);
  animation: cookieSlideUp 0.6s 1s ease-out;
  animation-fill-mode: forwards;
}

.cookie-warning-message {
  max-width: 91%;
  display: inline-block;
}

.cookie-warning p {
  margin-bottom: 0;
  font-size: 1em;
  display: inline-block;
}

.cookie-warning-action {
  width: fit-content;
  display: inline-block;
}

.cookie-warning .btn {
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1em;
  font-weight: 700;
  padding: 6px 8px;
}

.cookie-warning-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 45em) {
  .cookie-warning {
    padding-left: 30px;
    padding-right: 30px;
  }

  .cookie-warning-message {
    max-width: 92%;
    margin-bottom: 0;
    display: inline-block;
  }

  .cookie-warning-action {
    text-align: right;
  }
}
/* cookie consent spacer */
.cookie-consent-spacer {
  height: 80px;
  background-color: #0E2646;
}
@media screen and (min-width: 45em) {
  .cookie-consent-spacer {
    height: 50px;
  }
}

.cookie-dismissed .cookie-consent-spacer {
  display: none;
}

/* DISMISSED */
.cookie-dismissed .cookie-warning-wrapper {
  display: none;
}

/*
------------------------------------------------------------------------------- ILLUSTRATED GUIDE

------------------------------------------------------------------------------------------------
*/
.guide-summary {
  display: block;
  text-align: center;
  text-decoration: none;
  /*background-color: $bg-swoop;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.guide-summary-img {
  max-width: 720px;
  margin: 0 auto;
}

.guide-summary.portrait .guide-summary-img {
  max-width: 320px;
}

.guide-summary-img.guide-summary-img-bordered img {
  border: 4px solid #041e42;
  background-color: #041e42;
  margin: 0 auto;
}

.pswp__caption__center {
  text-align: center;
}

.pswp__button {
  border-radius: 0;
}

@media screen and (min-width: 45em) {
  .guide-summary-footer {
    line-height: 40px;
    padding: 20px 0;
  }

  .guide-summary-footer span {
    padding-left: 54px;
  }
}
/*
----------------------------------------------------------------------- NEWSLETTER SIGNUP STYLES

------------------------------------------------------------------------------------------------
*/
.newsletter-signup-container {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.newsletter-signup-container.fade-out {
  opacity: 0;
}

/*
---------------------------------------------------------------------------0000------ ADVERT CARD

------------------------------------------------------------------------------------------------
*/
.advert-wrapper {
  padding-top: 56px;
}
.advert-wrapper p {
  font-size: 1em;
  line-height: 1.2;
}

.summary-card.advert-card .summary-card-img-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
}

.react-phone-number-input {
  font-family: PlantinMTStd-Regular, Georgia, Times, "Times New Roman", serif;
}

.react-phone-number-input__row {
  /* This is done to stretch the contents of this component */
  display: flex;
  align-items: center;
  margin: 0;
  border: 1px solid #cecfcf;
  color: #454545;
  background-color: #ffffff;
  font-size: 20px;
  line-height: 1.6;
  width: 100%;
  height: 44px;
  outline: none;
  border-radius: 0;
  transition: border-color 0.3s ease-out, background 0.3s ease-out;
  border-radius: 0;
}

.react-phone-number-input__phone {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
    to make room for the extension input */
  min-width: 0;
}

.react-phone-number-input__icon {
  /* The flag icon size is 4x3 hence the exact `width` and `height` values */
  width: 1.24em;
  height: 0.93em;
  margin-left: 6px;
  /* `1px` is still too much for a "retina" screen but there's no way in CSS to specify "hairline" border width. */
  border: 1px solid rgba(0, 0, 0, 0.5);
  /* Makes sure `width`x`height` is exactly `4x3` and `border` width is not included in it */
  box-sizing: content-box;
}

.react-phone-number-input__icon--international {
  /* The international icon size is square */
  /* and also has no border hence the `2 * 1px` `width` and `height` compensation */
  width: calc(0.93em + 2px);
  height: calc(0.93em + 2px);
  /* The international icon size is square hence the exact `padding` value */
  /* for precise alignment with `4x3` sized country flags. */
  padding-left: 0.155em;
  padding-right: 0.155em;
  border: none;
}

.react-phone-number-input__error {
  margin-left: calc(1.24em + 2px + 0.3em + 0.35em + 0.5em);
  margin-top: 0.3rem;
  color: #d30f00;
}

.react-phone-number-input__icon-image {
  max-width: 100%;
  max-height: 100%;
}

/* Removes `<input type="number"/>` up/down arrows in Webkit browsers. */
.react-phone-number-input__ext-input::-webkit-inner-spin-button,
.react-phone-number-input__ext-input::-webkit-outer-spin-button {
  margin: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
}

.react-phone-number-input__ext-input {
  width: 3em;
}

.react-phone-number-input__ext {
  white-space: nowrap;
}

.react-phone-number-input__ext,
.react-phone-number-input__ext-input {
  margin-left: 0.5em;
}

/* Styling native country `<select/>`. */
.react-phone-number-input__country--native {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}

.react-phone-number-input__country-select {
  position: absolute;
  font-size: 14px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.react-phone-number-input__country-select-arrow {
  display: block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #b8bdc4;
  opacity: 0.7;
  transition: color 0.1s;
}

/* Something from stackoverflow. */
.react-phone-number-input__country-select-divider {
  font-size: 1px;
  background: black;
}

.react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
.react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
  color: #03b2cb;
}

/* Styling phone number input */
.react-phone-number-input__input {
  height: 1.8rem;
  outline: none;
  border-radius: 0;
  padding: 0;
  appearance: none;
  border: none;
  transition: border 0.1s;
  font-size: 16px;
}

.react-phone-number-input__input:focus {
  border-color: #03b2cb;
}

.react-phone-number-input__input--disabled {
  cursor: default;
}

.react-phone-number-input__input--invalid,
.react-phone-number-input__input--invalid:focus {
  border-color: #eb2010;
}

/* Overrides Chrome autofill yellow background color */
.react-phone-number-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}

/* A small fix for `react-responsive-ui` */
.react-phone-number-input__country .rrui__select__button {
  border-bottom: none;
}

/*
----------------------------------------------------------------------------------------- HEADER

------------------------------------------------------------------------------------------------
*/
.header {
  position: relative;
  z-index: 10;
}

.header-inner {
  background-color: inherit;
  padding-bottom: 2px;
}

@media screen and (max-width: 832px) {
  .header-inner,
.header {
    background-color: #041e42;
    background: linear-gradient(0.1turn, #041e42, #1d3455);
  }
}
.logo {
  display: block;
  margin: 0 20px 0 10px;
  float: left;
  transition: max-width 0.3s ease-out;
}

.logo img {
  display: block;
  height: auto;
}

.small-header .logo {
  max-width: 100px;
}

.header p {
  margin-bottom: 0;
}

.header-contact {
  clear: both;
  position: relative;
  min-height: 38px;
}
.header-contact:after {
  clear: both;
  content: "";
  display: table;
}

.ap-header-contact {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 38px;
  color: #ffffff;
}
.ap-header-contact .username {
  display: none;
}
.ap-header-contact .btn-logout {
  margin-top: 1em;
  left: 1em;
  width: calc(100% - 2em);
  text-align: center;
}

.header-contact .tel span {
  white-space: nowrap;
}

@media screen and (min-width: 770px) {
  .header .divider {
    display: none;
  }

  .logo {
    width: 54%;
    max-width: 530px;
    padding-right: 30px;
    border-bottom: none;
  }

  .logo img {
    margin-left: 0px;
    margin-top: 0px;
  }

  .header-contact {
    float: right;
    padding-bottom: 0;
    clear: none;
    border-top: none;
  }

  .header-contact {
    float: right;
    padding-bottom: 0;
    clear: none;
    border-top: none;
  }
  .header-contact .username {
    display: inline-block;
    margin-top: 0.75em;
    margin-right: 1em;
    font-size: 0.8em;
  }
  .header-contact .btn-logout {
    margin: 0;
    text-align: left;
    width: auto;
    left: 0;
    margin-top: 0;
  }

  .ap-header-contact {
    float: right;
    padding-top: 18px;
    padding-bottom: 0;
    clear: none;
    margin: 0;
    border-top: none;
  }
  .ap-header-contact .username {
    display: inline-block;
    margin-top: 0.75em;
    margin-right: 1em;
    font-size: 0.8em;
  }
  .ap-header-contact .btn-logout {
    margin: 0;
    text-align: left;
    width: auto;
    left: 0;
    margin-top: 0;
  }

  .header-contact a {
    float: right;
  }

  .header-contact .tel {
    font-size: 1.3em;
    line-height: 44px;
    margin: 0;
    text-align: left;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }

  .header-main .row {
    position: relative;
  }

  .header-inner,
.header-new {
    position: relative;
  }

  .header-inner .float-header {
    background: none;
    border-bottom: none;
  }
}
/* Header Basic */
.header-basic .header-contact .tel {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

@media screen and (min-width: 770px) {
  .header-basic .logo {
    max-width: 380px;
    padding-top: 26px;
    margin-bottom: 26px;
  }

  .header-basic .logo img {
    margin-bottom: 0;
  }

  .header-basic .header-contact {
    padding-top: 18px;
  }

  .header-basic .header-contact .tel {
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .logo {
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    max-width: 530px;
  }

  .logo img {
    margin-top: 0;
  }

  .header-contact {
    float: right;
    padding-bottom: 0;
    clear: none;
  }
}
@media screen and (min-width: 1024px) {
  .logo {
    max-width: 491px;
  }
}
@media screen and (max-width: 1024px) {
  .header-contact .tel {
    margin-left: 0;
  }
}
/* Header Fixed */
/*
------------------------------------------------------------------------------------- NAVIGATION

------------------------------------------------------------------------------------------------
*/
#desktop-search {
  -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.js .icon-menu {
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  line-height: 39px;
  font-size: 1em;
  text-transform: none;
  /*overflow:hidden; text-indent:-300px;*/
  float: right;
  width: 39px;
  overflow: hidden;
  word-wrap: normal;
  /* WORD WRAP IS IMPORTANT!! */
  white-space: nowrap;
  text-indent: 101%;
}

.icon-menu:before {
  width: 35px;
  height: 35px;
  margin: 0;
  position: absolute;
  top: 1px;
  right: 2px;
  content: "";
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Crect x='9.7' y='12.2' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='27.1' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='41.9' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='12.2' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='27.1' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='41.9' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3C/svg%3E");
}

.icon-menu:after {
  background-image: none;
}

.mob-nav-open .icon-menu:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpolygon style='fill:%23FFFFFF' points='34.2,30 48.1,43.9 43.9,48.1 30,34.1 16.1,48.1 11.9,43.9 25.9,30 11.9,16.1 16.1,11.9 30,25.8 43.9,11.9 48.1,16.1 '/%3E%3C/svg%3E");
}

.main-nav {
  background-color: #e8eaec;
  clear: both;
}
@media screen and (max-width: 833px) {
  .main-nav {
    top: 10px;
  }
}

.main-nav ul {
  margin: 0 !important;
  padding: 0;
  font-size: 0.9em;
  list-style-type: none;
}

.main-nav ul ul {
  font-size: 0.9em;
  background-color: #e8eaec;
}

.main-nav li {
  padding: 0;
}

.main-nav a {
  display: block;
  padding: 14px 30px 12px 12px;
  color: #041e42;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.main-nav a:hover,
.main-nav a.active {
  background-color: #d1d5d9;
}

.js .main-nav {
  max-height: 0;
  transition: max-height 0.4s ease-out, height 0.4s ease-out;
}

.js .mob-nav-open .header-inner .main-nav {
  max-height: none !important;
  z-index: 1;
  position: relative;
}

.js .main-nav ul {
  position: relative;
  transition: left 0.3s ease-out;
}

.overlay {
  z-index: 50;
  position: fixed;
  width: 100%;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: #1c1a1b;
}

.overlay-visible {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}

.overlay-hidden {
  opacity: 0;
}

/* If you have a sub navigation */
.js .main-nav ul ul {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 100%;
}

.js .menu-pos-0 {
  left: 0;
}

.js .menu-pos-1 {
  left: -100%;
}

.js .menu-pos-2 {
  left: -200%;
}

.js .menu-pos-3 {
  left: -300%;
}

.js .menu-pos-4 {
  left: -400%;
}

.js .icon-sub-back {
  opacity: 0.8;
}

.js .icon-sub-back:hover {
  opacity: 1;
}

.js .icon-sub::after {
  content: "ᐅ";
  color: inherit;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 7px;
  height: 12px;
}
@media screen and (min-width: 833px) {
  .js .icon-sub::after {
    content: "";
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23ffffff;' d='M30,37.9L16.4,22.1h27.3L30,37.9z M20.7,24.1L30,34.8l9.3-10.7H20.7z'/%3E%3C/svg%3E%0A");
    right: 0;
    top: 16px !important;
    width: 27px !important;
    height: 30px !important;
  }
}

.primary-nav > li > ul a.icon-sub::after {
  background-image: none;
  width: 7px !important;
  height: 12px !important;
}

.js .icon-sub-back::before {
  content: "ᐊ";
  color: inherit;
  float: left;
  width: 7px;
  margin-top: 0;
  margin-right: 10px;
}

/*
------------------------------------------------------------------------------------ COUNTRY NAV

------------------------------------------------------------------------------------------------
*/
.country-drop {
  position: relative;
  float: right;
}

.country-drop ul {
  background-color: #e8eaec;
  margin: 0;
  padding-top: 56px;
  position: absolute;
  top: -4px;
  left: 0;
  z-index: 100;
  overflow: hidden;
  border-radius: 4px;
  transition: opacity 0.3s ease-out;
}

.country-drop li {
  padding: 0;
  height: 48px;
  transition: opacity 0.3s ease-out;
}

.country-drop .flag-selected {
  position: relative;
  z-index: 101;
  cursor: pointer;
  opacity: 0.9;
  filter: grayscale(65%);
  transform: scale(0.95);
  transition: all 0.3s ease-out;
}
.country-drop .flag-selected:hover, .country-drop .flag-selected:focus {
  transform: scale(1);
  filter: none;
  opacity: 1;
}

/* open close */
.country-drop-show ul {
  height: auto;
  opacity: 1;
  box-shadow: 3px 3px 7px -2px rgba(0, 0, 0, 0.4);
}

.country-drop-hide ul {
  opacity: 0;
}

.country-drop li.flag-show:nth-child(1) {
  animation: delayFadeIn 400ms;
  -webkit-animation: delayFadeIn 400ms;
}
.country-drop li.flag-show:nth-child(2) {
  animation: delayFadeIn 600ms;
  -webkit-animation: delayFadeIn 600ms;
}
.country-drop li.flag-show:nth-child(3) {
  animation: delayFadeIn 800ms;
  -webkit-animation: delayFadeIn 800ms;
}
.country-drop li.flag-show:nth-child(4) {
  animation: delayFadeIn 1000ms;
  -webkit-animation: delayFadeIn 1000ms;
}
.country-drop li.flag-show:nth-child(5) {
  animation: delayFadeIn 1200ms;
  -webkit-animation: delayFadeIn 1200ms;
}
.country-drop li.flag-show:nth-child(6) {
  animation: delayFadeIn 1400ms;
  -webkit-animation: delayFadeIn 1400ms;
}
.country-drop li.flag-show:nth-child(7) {
  animation: delayFadeIn 1400ms;
  -webkit-animation: delayFadeIn 1400ms;
}

@-webkit-keyframes delayFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes delayFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
a.icon-flag, span.icon-flag {
  margin: 0 0 -8px;
}

a.icon-flag {
  opacity: 0.5;
  transform: scale(0.9);
  transition: opacity 0.3s ease-out, transform 0.3s;
}
a.icon-flag:hover {
  opacity: 1;
  transform: scale(1);
}

/*
------------------------------------------------------------------------------------ CONTENT NAV

------------------------------------------------------------------------------------------------
*/
.content-nav {
  display: none;
}

.content-nav {
  padding: 0.5em 0;
  background-color: #1d3455;
}
.content-nav:after {
  clear: both;
  content: "";
  display: table;
}

.content-nav-crumbs {
  list-style-type: none;
  margin: 0;
  font-size: 0.9em;
}

.content-nav-crumbs li {
  /*display: inline-block;*/
  float: left;
  position: relative;
  padding: 0 9px 0 9px;
  margin-right: 4px;
}

.content-nav-crumbs li:after {
  content: "";
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23FFFFFF' d='M40.1,31.4L24.5,46.9c-0.8,0.8-2,0.8-2.7,0L20,45.1c-0.8-0.8-0.8-2,0-2.7L32.3,30L20,17.6c-0.7-0.8-0.7-2,0-2.7l1.8-1.8 c0.8-0.8,2-0.8,2.7,0l15.6,15.5C40.8,29.4,40.8,30.6,40.1,31.4L40.1,31.4z'/%3E%3C/svg%3E");
  opacity: 0.6;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2px;
  right: -10px;
}

.content-nav-crumbs li:last-child {
  padding-right: 0;
}

.content-nav-crumbs li:last-child:after {
  display: none;
}

.content-nav-crumbs a {
  color: #ffffff;
}

.content-nav--mobile {
  position: absolute;
  width: 100%;
  display: block;
  background-color: rgb(29, 52, 85);
  z-index: 1;
}
.content-nav--mobile ul {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.content-nav--mobile li {
  display: none;
}
.content-nav--mobile li:last-of-type {
  display: inline-block;
}
.content-nav--mobile li:nth-last-of-type(2) {
  display: inline-block;
}
.content-nav--mobile li:nth-last-child(1)::after {
  content: "";
  display: inline-block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAGIQU8tAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzVFREM0RkUwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzVFREM0RkQwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIwMjAgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzVFREM0RjkwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzVFREM0RkEwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4yDFokAAACCElEQVR42mKUNgm3ZmBgMANieyC2AGIRJiDByIAGmJDYcEmAAGIEag8F0lZA7AzEykDMhazyPzbtcAAQQCDtNkBaEohlgFgOiBWhWByIeYGYHYiZmZCNwgcAAghkogeQloZiJSgGmS4EchzINJBzYCYyEjIRFhj/0X2ETeF/JAWMuExnQjMZp6kAAcQIjRweIOYHYj5okPBBsTAQCwCxIBTzQD3IAcSsUAyzhAk9Ev6Bbf3PxICsAMnpRAU3E5pfGRkY/zFgCQeiDEM2EF0DI1pYMRKKDWwuZCAiGgmmB4AAlJTRCsAgCEW5DnoZDNYn7f+/SUfMh3Ba+hCESOU99zagPCr2ZaDc6rKuwMY6FUpTIMcEBbNv4IzrjbwdmxJa2RoqUGSRHWRf6O2lYpWIMps6Za3ihdkCYXPB359C5W8kEv/rAac0xObAUlIkSAsqWr4CtFpFKxDDIGwRbtv9///We7GHiNH1rgNZxxA0JrHTvi5j/uViWtRt36e93y7ucJ55p4uXU9VUVlQYmCFL+BnhkgKdbo44NjzV2tOF2eqK2leqj6yOtlO5bDYWedDsMwKaDKXIi02OBl0t1gdYARziQVUHS2ZbIUNzEP5xxRJfqcwfX05iIBmxEo4pEdjPfs9E0o2ounKBcBQ7CswW+pGMzTcgCbpImt2CYCxAGrTiIkRzL/jLEz+PDHnKFrHw6wAAAABJRU5ErkJggg==") 0 0 no-repeat;
  width: 40px;
  height: 20px;
  right: 0px;
}

@media screen and (min-width: 833px) {
  /* ---------------------- NAVIGATION */
  .header-nav {
    clear: both;
    border-top: 4px solid #ffffff;
  }
  .header-nav:after {
    clear: both;
    content: "";
    display: table;
  }

  .js .main-nav,
.main-nav {
    max-height: none;
    overflow: visible;
    clear: none;
    background-color: transparent;
  }

  .js .icon-menu,
.icon-menu {
    display: none;
  }

  .main-nav {
    padding-top: 0;
    float: left;
    width: auto;
  }

  .main-nav ul {
    float: left;
  }
  .main-nav ul:after {
    clear: both;
    content: "";
    display: table;
  }

  .main-nav ul,
.js .main-nav ul {
    transition: none;
  }
  .main-nav ul:after,
.js .main-nav ul:after {
    clear: both;
    content: "";
    display: table;
  }

  .main-nav li {
    float: left;
    position: relative;
  }

  .main-nav a {
    padding: 20px 30px 16px 20px;
    color: #ffffff;
  }

  .main-nav a:hover,
.main-nav > ul > li:hover > a {
    background-color: #ffffff;
    color: #041e42;
  }

  .js .icon-sub:after {
    width: 12px;
    height: 7px;
    top: 20px;
  }

  .js .icon-sub > a:after {
    display: none;
  }

  .js .main-nav ul ul,
.main-nav ul ul {
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, 0.95);
    font-size: 1em;
    display: none;
    width: 216px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 500;
  }

  .main-nav li:hover > ul {
    display: block;
  }

  .main-nav li.show-sub > ul,
.js .main-nav li.show-sub > ul {
    display: block;
  }

  .main-nav ul ul li {
    float: none;
    margin-left: 0;
  }

  .main-nav ul ul ul,
.js .main-nav ul ul ul {
    position: absolute;
    left: 100%;
    top: 0;
  }

  .js .main-nav ul ul a,
.main-nav ul ul a {
    color: #041e42;
    text-transform: none;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .js .main-nav ul ul .icon-sub:after,
.main-nav ul ul .icon-sub:after {
    content: "ᐅ";
    width: 7px;
    height: 12px;
    top: 14px;
  }

  .main-nav ul ul a:hover,
.main-nav ul ul li:hover > a {
    background-color: #e8eaec;
  }

  .js .main-nav ul ul .icon-sub,
.main-nav ul ul .icon-sub {
    padding-right: 18px;
  }

  .js .main-nav ul ul .icon-sub:after,
.main-nav ul ul .icon-sub:after {
    right: 10px;
  }

  .main-nav .icon-sub:hover ul {
    display: block;
  }

  .js .main-nav .icon-sub:hover ul {
    display: none;
  }

  .js .main-nav ul .js-nav-open ul,
.js .main-nav .js-nav-open:hover ul {
    display: block;
  }

  .main-nav li:hover > ul {
    display: block;
  }

  .nav-aside {
    float: right;
    position: relative;
  }

  .content-nav {
    display: block;
  }

  .content-nav--mobile {
    display: none;
  }

  .float-header .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .float-header .header-inner,
.float-header .content-nav {
    background: transparent;
  }

  .float-header .banner-inner {
    padding-top: 343px;
  }

  .float-header .banner-simple {
    padding-top: 220px;
  }

  .float-header .header-nav {
    min-height: 4.1rem;
    border-bottom: 4px solid #fff;
  }
}
@media (max-width: 834px) {
  .mobile-expand {
    width: 100%;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #2c5282;
  }

  .desktop__hide {
    display: none;
  }

  .mobile__show {
    display: block;
  }
}
@media screen and (max-width: 1000px) {
  .main-nav a {
    padding: 20px 25px 16px 15px;
    font-size: 0.9rem;
  }
}
.nav-edit {
  font-family: montserrat;
  font-size: 0.75em;
  padding: 0;
  border-radius: 5px;
  display: inline-block;
  position: relative;
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
/*
-------------------------------------------------------------------------- BANNERS / PAGE INTROS

------------------------------------------------------------------------------------------------
*/
.banner {
  color: #ffffff;
  text-align: center;
  background-color: #041e42;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  padding: 0;
}

.offer .banner-content {
  padding-bottom: 36px;
}
.offer .banner-content p:nth-last-child(1) {
  margin-top: -3%;
}

.banner-inner {
  position: relative;
}
.banner-inner--location {
  background: linear-gradient(0.1turn, #041e42, #1d3455 150%);
}

.banner h1 {
  color: #ffffff;
}

.banner-content {
  max-width: 930px;
  margin: 0 auto;
}

.banner-content p {
  font-size: 1rem;
  margin-bottom: 26px;
  text-shadow: 0px 0px 15px #272727;
}
@media screen and (min-width: 833px) {
  .banner-content p {
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}

.banner .btn {
  margin: 0;
}

.banner:before,
.banner-inner:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0b0c18;
  opacity: 0.5;
}

.banner-inner:before {
  background-color: transparent;
  opacity: 1;
  background: url("../img/banner-fade-overlay.png") 0 0 repeat-x;
}

.banner-content-page {
  background-color: #1d3455;
}

.banner-content-page:before,
.banner-content-page .banner-inner:before {
  display: none;
}

.banner-content-page .banner-inner {
  padding-top: 40px;
  padding-top: 40px;
}

.banner-content-page .banner-inner {
  padding-top: 20px;
  padding-bottom: 40px;
}

@media screen and (min-width: 833px) {
  .banner-inner:before {
    opacity: 1;
  }

  .float-header .banner-inner {
    padding-top: 270px;
  }

  .float-header .banner-simple {
    padding-top: 280px;
  }
}
@media screen and (min-width: 62em) {
  /* ---------------------- BANNERS / CAROUSELS */
  .float-header .banner-inner {
    padding-top: 290px;
  }

  .float-header .banner-simple {
    padding-top: 220px;
  }
}
@media screen and (min-width: 1024px) {
  /* ---------------------- BANNERS / CAROUSELS */
  .float-header .banner-simple {
    padding-top: 160px;
  }
}
.sw-bg-navy {
  background-color: #041e42;
}

.sw-orange {
  color: #e66c28;
}

.sw-orange-bg {
  background-color: #e66c28;
}

.border-offset-green {
  border-color: #5ab54a;
}

.border-sw-navy {
  border-color: #041e42;
}

.border-sw-orange {
  border-color: #e66c28;
}

.bg-offset-green {
  background-color: #5ab54a;
}

.text-green {
  color: #5ab54a;
}

.sw-font-sans {
  font-family: "Montserrat", helvetica, sans-serif;
}

.center {
  margin: 0 auto !important;
  left: 0;
  right: 0;
}

.info_icon {
  background-repeat: no-repeat;
}
.info_icon--sm {
  height: 30px;
  width: 30px;
}
.info_icon--md {
  height: 60px;
  width: 60px;
}
.info_icon--lg {
  height: 100px;
  margin: 14% 0;
  padding: 0 2em;
}

.carbon-tag > sub {
  font-size: 0.6em;
}

.badge {
  display: inline-block;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  position: relative;
  top: -3px;
  left: 5px;
  border-radius: 100%;
  padding: 0.4rem 0.6rem;
}

.badge-orange {
  background-color: #e66c28;
  color: #ffffff;
}

.badge-navy {
  background-color: #041e42;
  color: #f9f9f9;
}

.popup {
  opacity: 0;
  visibility: hidden;
}
.popup-container {
  background: #3e506f;
}
.popup-trigger {
  color: #fff;
}

.is-visible {
  opacity: 1 !important;
  visibility: visible !important;
}

.close:hover {
  opacity: 0.5;
  cursor: pointer;
}

.barchart {
  width: 100%;
  fill: #fff;
  animation: riseup 1s 1;
  animation-delay: 1s;
}
.barchart .bg-gray {
  fill: #a0aec0;
}
.barchart .active {
  fill: #e66c28;
}

.page-mask {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(4, 30, 66, 0.9);
  z-index: 30;
}

@keyframes riseup {
  0% {
    -webkit-clip-path: inset(100% 0 0 0);
    clip-path: inset(100% 0 0 0);
    opacity: 0.3;
  }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}
.no-csstransforms .sticky-landing-cta-header {
  opacity: 0;
}

.sticky-landing-cta-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 500;
  transform: translate(0, -100%);
  transition: transform 0.3s ease-out;
  opacity: 0;
}

.show-sticky-landing-cta-header .sticky-landing-cta-header {
  opacity: 1;
  transform: translate(0, 0);
}

.remove-sticky-landing-cta-header .sticky-landing-cta-header {
  opacity: 1;
  transform: translate(0, -100%);
}

.no-csstransforms .show-sticky-landing-cta-header .sticky-landing-cta-header {
  display: block;
  opacity: 1;
}

.ga-btn-anchor, .ga-sticky-btn-anchor, .tp-ga-btn-anchor, .tp-ga-sticky-btn-anchor {
  border-color: transparent;
  color: #ffffff;
  line-height: 1.1;
  padding: 15px 10px;
  position: relative;
  text-decoration: none !important;
}

.ga-btn-anchor, .ga-sticky-btn-anchor {
  background-color: #FF985F;
}

.tp-ga-btn-anchor, .tp-ga-sticky-btn-anchor {
  background-color: #748193;
}

.ga-btn-anchor:hover, .ga-sticky-btn-anchor:hover, .ga-btn-anchor.active, .ga-sticky-btn-anchor.active, .tp-ga-btn-anchor:hover, .tp-ga-sticky-btn-anchor:hover, .tp-ga-btn-anchor.active, .tp-ga-sticky-btn-anchor.active {
  background-color: #ffffff;
  color: #272727;
}

.ga-sticky-btn-anchor.active, .tp-ga-sticky-btn-anchor.active {
  margin-bottom: 9px;
}

.ga-btn-anchor.active::after, .ga-sticky-btn-anchor.active::after, .tp-ga-btn-anchor.active::after, .tp-ga-sticky-btn-anchor.active::after {
  position: absolute;
  content: "";
  bottom: -10px;
  right: calc(50% - 10px);
  width: 0;
  height: 0;
  border-width: 10px 10px 0 10px;
  border-color: #FFFFFF transparent transparent transparent;
  border-style: solid;
}

section[id] {
  border-top: 20px solid transparent;
  margin-top: -20px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

@media screen and (max-width: 719px) {
  .sticky-landing-cta-header a:nth-child(1), .sticky-trip-cta-header a:nth-child(1) {
    margin-left: 0 !important;
  }

  .anchor-row-wrapper {
    padding: 0;
  }

  .anchor-row {
    margin-right: 20px !important;
  }
}
.expert-banner {
  opacity: 0;
  height: 0;
}

.expert-banner.left-aligned {
  margin-top: -2rem;
}

.expert-banner.opacity-100 {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s both;
  height: auto;
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

.arrow-left {
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2c5282;
  top: 0.3rem;
  left: -9px;
  position: absolute;
}

/**
	* ----------------------------------------
	* animation fade-in-top
	* ----------------------------------------
	*/
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.ping {
  -webkit-animation: ping 2.5s ease-in infinite both;
  animation: ping 2.5s ease-in infinite both;
}

/**
* ----------------------------------------
* animation ping
* ----------------------------------------
*/
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
/*flag icons here */
.icon-flag {
  width: 44px;
  height: 44px;
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
  text-indent: 101%;
  text-align: left;
  position: relative;
}

@media (max-width: 834px) {
  .icon-flag {
    width: 40px;
    height: 40px;
  }
}
.flag {
  height: 33px;
  width: 33px;
}

.flag, .icon-flag {
  background-repeat: no-repeat;
  display: inline-block;
}

.flag-au, .icon-aud, .icon-aus {
  background-image: url("/assets/front/app/img/svg-icon/flags/au.svg");
}

.flag-be {
  background-image: url("/assets/front/app/img/svg-icon/flags/be.svg");
}

.flag-ca, .icon-cad {
  background-image: url("/assets/front/app/img/svg-icon/flags/ca.svg");
}

.flag-ch {
  background-image: url("/assets/front/app/img/svg-icon/flags/ch.svg");
}

.flag-cz {
  background-image: url("/assets/front/app/img/svg-icon/flags/cz.svg");
}

.flag-de {
  background-image: url("/assets/front/app/img/svg-icon/flags/de.svg");
}

.flag-dk {
  background-image: url("/assets/front/app/img/svg-icon/flags/dk.svg");
}

.flag-es {
  background-image: url("/assets/front/app/img/svg-icon/flags/es.svg");
}

.flag-fr {
  background-image: url("/assets/front/app/img/svg-icon/flags/fr.svg");
}

.flag-ie {
  background-image: url("/assets/front/app/img/svg-icon/flags/ie.svg");
}

.flag-it {
  background-image: url("/assets/front/app/img/svg-icon/flags/it.svg");
}

.flag-mx {
  background-image: url("/assets/front/app/img/svg-icon/flags/mx.svg");
}

.flag-nl {
  background-image: url("/assets/front/app/img/svg-icon/flags/nl.svg");
}

.flag-no {
  background-image: url("/assets/front/app/img/svg-icon/flags/no.svg");
}

.flag-nz {
  background-image: url("/assets/front/app/img/svg-icon/flags/nz.svg");
}

.flag-pl {
  background-image: url("/assets/front/app/img/svg-icon/flags/pl.svg");
}

.flag-pt {
  background-image: url("/assets/front/app/img/svg-icon/flags/pt.svg");
}

.flag-sv, .flag-se {
  background-image: url("/assets/front/app/img/svg-icon/flags/sv.svg");
}

.flag-us, .icon-usd {
  background-image: url("/assets/front/app/img/svg-icon/flags/us.svg");
}

.flag-gb, .icon-gbp {
  background-image: url("/assets/front/app/img/svg-icon/flags/gb.svg");
}

.flag-sg {
  background-image: url("/assets/front/app/img/svg-icon/flags/sg.svg");
}

.flag-ru {
  background-image: url("/assets/front/app/img/svg-icon/flags/ru.svg");
}

.flag-cr {
  background-image: url("/assets/front/app/img/svg-icon/flags/cr.svg");
}

.flag-pe {
  background-image: url("/assets/front/app/img/svg-icon/flags/pe.svg");
}

.flag-mk {
  background-image: url("/assets/front/app/img/svg-icon/flags/mk.svg");
}

.flag-my {
  background-image: url("/assets/front/app/img/svg-icon/flags/my.svg");
}

.flag-cn {
  background-image: url("/assets/front/app/img/svg-icon/flags/cn.svg");
}

.flag-co {
  background-image: url("/assets/front/app/img/svg-icon/flags/co.svg");
}

.flag-lt {
  background-image: url("/assets/front/app/img/svg-icon/flags/lt.svg");
}

.flag-ar {
  background-image: url("/assets/front/app/img/svg-icon/flags/ar.svg");
}

.flag-kz {
  background-image: url("/assets/front/app/img/svg-icon/flags/kz.svg");
}

.flag-gr {
  background-image: url("/assets/front/app/img/svg-icon/flags/gr.svg");
}

.flag-jp {
  background-image: url("/assets/front/app/img/svg-icon/flags/jp.svg");
}

.flag-eur, .icon-eur {
  background-image: url("/assets/front/app/img/svg-icon/flags/eur.svg");
}

.flag-th {
  background-image: url("/assets/front/app/img/svg-icon/flags/th.svg");
}

.flag-tw {
  background-image: url("/assets/front/app/img/svg-icon/flags/tw.svg");
}

.flag-za {
  background-image: url("/assets/front/app/img/svg-icon/flags/za.svg");
}

.flag-id {
  background-image: url("/assets/front/app/img/svg-icon/flags/id.svg");
}

.flag-hu {
  background-image: url("/assets/front/app/img/svg-icon/flags/hu.svg");
}

.flag-hk {
  background-image: url("/assets/front/app/img/svg-icon/flags/hk.svg");
}

.flag-ae {
  background-image: url("/assets/front/app/img/svg-icon/flags/ae.svg");
}

.flag-in {
  background-image: url("/assets/front/app/img/svg-icon/flags/in.svg");
}

.flag-gt {
  background-image: url("/assets/front/app/img/svg-icon/flags/gt.svg");
}

.flag-bs {
  background-image: url("/assets/front/app/img/svg-icon/flags/bs.svg");
}

.flag-cl {
  background-image: url("/assets/front/app/img/svg-icon/flags/cl.svg");
}

.flag-lu {
  background-image: url("/assets/front/app/img/svg-icon/flags/lu.svg");
}

.flag-at {
  background-image: url("/assets/front/app/img/svg-icon/flags/at.svg");
}

.wpwl-container {
  font-size: 16px;
  padding: 2em;
}

.wpwl-button.wpwl-button-pay {
  margin-right: 0;
  min-width: 200px;
  font-family: "Montserrat", helvetica, sans-serif;
}

@media (max-width: 834px) {
  .scroller-inner .header-alert .banner-inner.webinar {
    padding-top: 240px;
  }
}
@media (min-width: 1024px) {
  .float-header.header-alert .banner-inner.webinar {
    padding-top: 340px;
  }
}
img {
  min-height: 50px;
  font-size: 14px;
  text-align: center;
}

figure.w-3\/12 img, figure.w-4\/12 img {
  min-height: 120px;
  background-color: #ddd;
}

.guide-summary.portrait .guide-summary-img, .content-block-img, div.illustrated-guide {
  min-height: 100px;
}

img, .avatar-row img, .guide-summary.portrait .guide-summary-img, .content-block-img img {
  background-color: #e8eaec;
}

footer img, header img, img.advert-card-logo, .avatar-img img, .summary-card-img img, .gm-style img, img.add-on-activity-icon, .js-lightbox img {
  background-color: transparent;
}

.gm-style img {
  min-height: auto;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  bottom: 0 !important;
  top: auto !important;
}

.gallery .swiper-container, .reviews .swiper-container {
  margin-bottom: 4rem;
}
.gallery .swiper-button-next, .gallery .swiper-button-prev, .reviews .swiper-button-next, .reviews .swiper-button-prev {
  background-color: rgba(29, 52, 85, 0.5);
  transition: background-color ease-in-out 300ms;
}
.gallery .swiper-button-next:hover, .gallery .swiper-button-prev:hover, .reviews .swiper-button-next:hover, .reviews .swiper-button-prev:hover {
  background-color: rgba(29, 52, 85, 0.85);
}
.gallery .swiper-button-next.swiper-button-white, .gallery .swiper-button-prev.swiper-button-white, .reviews .swiper-button-next.swiper-button-white, .reviews .swiper-button-prev.swiper-button-white {
  padding: 2.5rem 1.5rem;
  border-radius: 4px;
}

.reviews .swiper-button-prev, .reviews .swiper-button-next {
  top: 35%;
}
.reviews .swiper-button-prev.swiper-button-white {
  left: 1px;
}
.reviews .swiper-button-next.swiper-button-white {
  right: 1px;
}

.gallery .swiper-pagination-bullet-active {
  background: rgb(230, 108, 40);
}
.gallery .swiper-slide .caption {
  position: absolute;
  max-width: 500px;
  z-index: 2;
  bottom: 0;
  background-color: rgba(29, 52, 85, 0.9);
  color: #fff;
  margin: 0;
  padding: 0.5rem 1rem 0.1rem 0.1rem;
  font-size: 0.9rem;
  line-height: 1;
  text-align: left;
  display: flex;
}
.gallery .swiper-slide .caption span {
  content: url(../img/svg-icon/map-marker-wht.svg);
  height: 30px;
  width: 30px;
  margin-top: -8px;
  color: #fff;
  fill: #fff;
  opacity: 0.5;
  flex-shrink: 0;
}
.gallery .swiper-slide.swiper-slide-active .caption {
  transition-delay: 500ms;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.gallery .swiper-slide img {
  width: auto;
  max-height: 600px;
}
.gallery.full-screen .swiper-slide {
  max-height: 90vh;
}
.gallery.full-screen .swiper-slide .caption {
  font-size: 1.2rem;
}

.quotes.swiper-container, .swoop_team.swiper-container {
  max-width: 1024px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.quotes blockquote, .quote blockquote, .swoop_team blockquote {
  padding: 0 2.5rem;
}
.quotes .avatar-img, .quote .avatar-img, .swoop_team .avatar-img {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}
.quotes .avatar-img::before, .quote .avatar-img::before, .swoop_team .avatar-img::before {
  display: block;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.5;
}
.quotes .avatar-img img, .quote .avatar-img img, .swoop_team .avatar-img img {
  transform: scale(0.95);
}

.quotes .quote-content p, .quote .quote-content p, .swoop_team .quote-content p {
  line-height: 1.3;
  padding: 0 4rem;
}

.quote .avatar-img, .swoop_team .avatar-img {
  width: 120px;
  height: 120px;
}

.trips.swiper-container, .you-may-like.swiper-container {
  overflow: hidden !important;
  padding-bottom: 2rem;
}
.trips .swiper-slide .summary-card-img-crop, .you-may-like .swiper-slide .summary-card-img-crop {
  position: relative;
  overflow: hidden;
}
.trips .swiper-slide.swiper-slide-active .summary-card-img > a picture:hover::after, .you-may-like .swiper-slide.swiper-slide-active .summary-card-img > a picture:hover::after {
  opacity: 1;
}
.trips .swiper-slide.swiper-slide-active .summary-card-img > a picture::after, .you-may-like .swiper-slide.swiper-slide-active .summary-card-img > a picture::after {
  content: "View trip";
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: "Montserrat", helvetica, sans-serif;
  font-size: 1rem;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  opacity: 0;
  background-color: rgba(29, 52, 85, 0.7);
  color: #fff;
}
@media screen and (max-width: 1100px) and (min-width: 800px), (min-width: 1100px) {
  .trips .swiper-slide .summary-card-content.has-content, .you-may-like .swiper-slide .summary-card-content.has-content {
    min-height: 345px;
  }
}
.trips .swiper-button-next, .trips .swiper-button-prev, .you-may-like .swiper-button-next, .you-may-like .swiper-button-prev {
  background-color: rgba(29, 52, 85, 0.2);
  transition: background-color ease-in-out 300ms;
}
.trips .swiper-button-next:hover, .trips .swiper-button-prev:hover, .you-may-like .swiper-button-next:hover, .you-may-like .swiper-button-prev:hover {
  background-color: rgba(29, 52, 85, 0.85);
}
.trips .swiper-button-next.swiper-button-white, .trips .swiper-button-prev.swiper-button-white, .you-may-like .swiper-button-next.swiper-button-white, .you-may-like .swiper-button-prev.swiper-button-white {
  padding: 2rem 1.5rem;
  border-radius: 4px;
}
.trips .swiper-pagination.swiper-pagination-bullets, .you-may-like .swiper-pagination.swiper-pagination-bullets {
  bottom: 0px;
}
.trips .swiper-pagination-bullet, .you-may-like .swiper-pagination-bullet {
  transition: transform ease-in 100ms;
}
.trips .swiper-pagination-bullet:hover, .you-may-like .swiper-pagination-bullet:hover {
  transform: scale(1.4);
}
.trips .swiper-pagination-bullet-active, .you-may-like .swiper-pagination-bullet-active {
  background: rgba(29, 52, 85, 0.9);
}

/**
 * ----------------------------------------
 * media query
 * ----------------------------------------
 */
@media (min-width: 1280px) {
  .gallery.full-screen .swiper-slide .caption {
    font-size: 1.4rem;
  }
}
@media (max-width: 768px) {
  .quotes .avatar-img, .quote .avatar-img, .swoop_team .avatar-img {
    width: 100px;
    height: 100px;
  }
  .quotes .avatar-img::before, .quote .avatar-img::before, .swoop_team .avatar-img::before {
    height: 70px;
    width: 70px;
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0.5;
  }
}
@media (max-width: 640px) {
  .reviews .swiper-button-prev, .reviews .swiper-button-next {
    display: none;
  }

  .gallery .swiper-container {
    margin-bottom: 3.2rem;
  }
  .gallery .swiper-slide .caption {
    font-size: 12px;
    padding: 0.5rem 1.1rem 0.5rem 0.5rem;
  }
  .gallery .swiper-slide .caption span {
    margin-top: -5px;
    margin-right: 1px;
    height: 20px;
    width: 20px;
  }
  .gallery.full-screen .swiper-slide .caption {
    font-size: 1rem;
  }
  .gallery.full-screen .swiper-slide .caption span {
    margin-top: -5px;
    margin-right: 1px;
    height: 25px;
    width: 25px;
  }

  .quotes blockquote, .quote blockquote, .swoop_team blockquote {
    padding: 0 2.5rem;
  }
  .quotes blockquote .quote-content p, .quote blockquote .quote-content p, .swoop_team blockquote .quote-content p {
    font-size: 16px;
    padding: 0 20px;
  }

  .trips.swiper-container, .you-may-like.swiper-container {
    padding-bottom: 0.3rem;
  }
  .trips .swiper-pagination.swiper-pagination-bullets, .you-may-like .swiper-pagination.swiper-pagination-bullets {
    bottom: 15px;
  }
  .trips .swiper-slide, .you-may-like .swiper-slide {
    transform: scale(0.95) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(10);
    transform: translateY(10);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(10);
    transform: translateY(10);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 1;
  }
}
.trip-img-thumb .swiper-slide {
  background-color: rgba(29, 52, 85, 0.85);
  border-radius: 5px;
  overflow: hidden;
  opacity: 0.5;
  cursor: pointer;
  height: auto;
  border: 3px solid #ffffff;
}
.trip-img-thumb .swiper-slide:hover {
  opacity: 0.9;
}
.trip-img-thumb .swiper-slide-thumb-active {
  opacity: 1;
  border-color: darkorange;
}

.trip-img .swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #041e42;
}
.trip-img .swiper-slide {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.trip-img .swiper-slide img {
  min-width: 620px;
  min-height: 340px;
  vertical-align: middle;
}
.trip-img .swiper-slide::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  content: "";
  background-image: linear-gradient(180deg, rgba(3, 22, 47, 0), rgba(3, 22, 47, 0.9));
}
.trip-img .swiper-slide.swiper-slide-active .caption {
  transition-delay: 500ms;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.trip-img .swiper-button-next, .trip-img .swiper-button-prev {
  background-color: rgba(29, 52, 85, 0.5);
  transition: background-color ease-in-out 300ms;
}
.trip-img .swiper-button-next:hover, .trip-img .swiper-button-prev:hover {
  background-color: rgba(29, 52, 85, 0.85);
}
.trip-img .swiper-button-next.swiper-button-white, .trip-img .swiper-button-prev.swiper-button-white {
  padding: 2rem 1.5rem;
  border-radius: 4px;
}

#month-scroll-container {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
#month-scroll-container::-webkit-scrollbar {
  width: 10px;
}
#month-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#month-scroll-container::-webkit-scrollbar-thumb {
  background: #888;
}
#month-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#dates-prices .trips-table-cruise .table {
  scroll-snap-align: start;
  scroll-snap-stop: normal;
}
#dates-prices .trips-table-cruise .table.table-auto tr.title .col-cta {
  background-color: #8c97a2 !important;
}
#dates-prices .trips-table-cruise .table.table-auto .arrows {
  display: none;
}
#dates-prices .trips-table-cruise .table.table-auto .graident::after {
  background-image: none;
}
#dates-prices .trips-table-cruise .table thead tr {
  background-color: #edf2f7;
}
#dates-prices .trips-table-cruise .table thead th {
  height: auto;
  width: 110px;
  font-size: 13px;
  line-height: 1.3;
  text-align: center;
  padding: 4px 10px;
  font-weight: 600;
  border: 1px solid #cbd5e0;
}
#dates-prices .trips-table-cruise .table tbody {
  border: 1px solid #e2e8f0;
  font-size: 14px;
}
#dates-prices .trips-table-cruise .table tbody td {
  text-align: center;
  padding: 15px 10px;
  line-height: 1.2;
}
#dates-prices .trips-table-cruise .table tbody tr.title td {
  text-align: left;
  padding-left: 15px;
  font-weight: 600;
}
#dates-prices .trips-table-cruise .table tbody .currency-amount, #dates-prices .trips-table-cruise .table tbody .col-date > a {
  color: #4a5568;
}
#dates-prices .trips-table-cruise .table tbody .currency-amount:hover, #dates-prices .trips-table-cruise .table tbody .col-date > a:hover {
  color: #ed8936;
}
#dates-prices .trips-table-cruise .table tbody .currency-amount {
  font-size: 14px;
}
#dates-prices .trips-table-cruise .table tbody .currency-symbol {
  font-size: 12px;
  color: #4a5568;
  padding-right: 3px;
}
#dates-prices .trips-table-cruise .table tbody .currency-amount, #dates-prices .trips-table-cruise .table tbody .currency-symbol {
  display: inline;
}
#dates-prices .trips-table-cruise .table tbody .text-small .currency-amount, #dates-prices .trips-table-cruise .table tbody .text-small .currency-symbol {
  font-size: 0.8rem;
}
#dates-prices .trips-table-cruise .table tbody .sale-tag {
  padding: 0.1rem 0.25rem;
  font-size: 0.8em;
  border-radius: 0.1rem;
}
#dates-prices .trips-table-cruise .table .even td, #dates-prices .trips-table-cruise .table .even .sale-tag {
  background-color: #f7f7f7;
}

.date-picker .swiper-button-next, .date-picker .swiper-button-prev {
  background-color: rgba(230, 108, 40, 0.7);
  transition: background-color ease-in-out 300ms;
  top: 43%;
}
.date-picker .swiper-button-next {
  right: 0;
}
.date-picker .swiper-button-prev {
  left: 0;
}
.date-picker .swiper-button-next:hover, .date-picker .swiper-button-prev:hover {
  background-color: rgb(230, 108, 40);
}
.date-picker .swiper-button-next.swiper-button-white, .date-picker .swiper-button-prev.swiper-button-white {
  border-radius: 0;
  height: 50%;
}
.date-picker .swiper-button-prev::after, .date-picker .swiper-button-next::after {
  font-size: 20px;
}
.date-picker .swiper-button-prev.swiper-button-disabled, .date-picker .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

.arrows {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 40px;
  bottom: 20px;
  margin-left: -20px;
  overflow: visible;
}

.arrows path {
  animation: arrow 4s infinite;
  -webkit-animation: arrow 4s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
    transform: translatex(10px);
  }
  80% {
    opacity: 1;
    transform: translatex(50px);
  }
  100% {
    opacity: 0;
    transform: translatex(50px);
  }
}
@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
    transform: translatex(10px);
  }
  80% {
    opacity: 1;
    transform: translatex(50px);
  }
  100% {
    opacity: 0;
    transform: translatex(50px);
  }
}
.arrows path.a1 {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
  /* Safari and Chrome */
}

[x-cloak] {
  display: none;
}

.trips-itinerary-viewer > h4 > i {
  font-size: 1.1rem;
}

@media (max-width: 640px) {
  .trip-img .swiper-slide img {
    min-height: 100%;
    min-width: 100%;
  }

  .reviews.swiper-container::before {
    width: 70px;
  }

  .trip-img .swiper-slide::after {
    height: 100px;
  }

  .trip-nav a:nth-child(1) {
    margin-left: 10px;
  }

  .sticky-trip-cta-header a:nth-child(1) {
    margin-left: 10px;
  }

  .trip-nav.more-content::before {
    display: none;
  }

  .trip-img .swiper-slide .caption {
    font-size: 12px;
    left: 1rem;
  }
  .trip-img .swiper-slide .caption span {
    margin-top: -5px;
    margin-right: 1px;
    height: 20px;
    width: 20px;
  }
}
@media (max-width: 719px) {
  #dates-prices .trips-table-cruise .table tbody tr.title td {
    padding: 15px 0 15px 10px;
  }
  #dates-prices .trips-table-cruise .table tbody tr td:nth-child(1) {
    font-weight: 600;
  }
  #dates-prices .trips-table-cruise .table tbody td {
    padding: 12px 10px 12px;
    text-align: right;
  }
  #dates-prices .trips-table-cruise .table tbody td::before {
    font-size: 15px;
    line-height: 0;
    display: block;
    font-weight: 400;
  }
  #dates-prices .trips-table-cruise .table tbody td.col-date {
    padding: 10px 10px 26px;
  }
  #dates-prices .trips-table-cruise .table tbody td.col-date a {
    float: left;
  }
  #dates-prices .trips-table-cruise .table tbody .currency-amount {
    font-size: 15px;
  }
  #dates-prices .table tr.title td.hidden {
    display: none;
  }
}
@media (min-width: 1024px) {
  .trip-nav a, .trip-banner-wrapper a {
    min-width: 160px;
    text-align: center;
  }
}
@media (min-width: 720px) {
  #dates-prices .trips-table-cruise .table .col-cta {
    width: 120px !important;
    border: 1px solid #cbd5e0;
  }
  #dates-prices .table {
    border-right: 1px solid #cbd5e0;
  }
  #dates-prices .table td, #dates-prices .table .even td {
    border-right: 1px solid #cbd5e0;
  }

  .graident {
    margin-right: 10em;
  }
  .graident::after {
    display: block;
    position: absolute;
    top: 0;
    left: -24px;
    height: 100%;
    width: 20%;
    content: "";
    background-image: linear-gradient(90deg, rgba(139, 151, 162, 0), rgba(139, 151, 162, 0.3));
  }
}
@media (min-width: 1280px) {
  .show-mobile {
    display: none;
  }
}
@media (max-width: 1024px) {
  .show-mobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .split-input div.form-group {
    width: 100%;
  }

  body.trip-page .trip-banner-wrapper.bg-swoop-secondary {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
  body.trip-page .scroller-inner .trip-banner-wrapper.bg-swoop-secondary {
    padding-top: 10rem;
  }
  body.trip-page .trip-banner-content > p {
    font-size: 1.1rem;
  }
  body.trip-page .main-nav ul > li {
    font-size: 1rem;
  }
  body.trip-page .js-trip-title {
    margin-bottom: 10px;
  }

  .show-desktop {
    display: none;
  }

  .show-mobile {
    display: block;
  }
}
@media (max-width: 640px) {
  .enquiry-form {
    padding: 20px !important;
  }

  .enquiry-form.streamlinedForm label[for=firstName],
.enquiry-form.streamlinedForm label[for=email],
.enquiry-form.streamlinedForm label[for=tel] {
    display: none;
  }

  label,
.form-control {
    font-size: 16px;
  }

  .show-mobile {
    display: block;
  }

  body.trip-page .trip-banner-wrapper.bg-swoop-secondary {
    padding-top: 4rem;
  }
  body.trip-page .scroller-inner .trip-banner-wrapper.bg-swoop-secondary {
    padding-top: 5rem;
  }
}
html, .menu-container, .nav-scroller {
  height: 100%;
}

.nav-scroller,
.nav-scroller-inner {
  position: relative;
}

.menu-container {
  position: relative;
  overflow-x: hidden;
}

.mp-pusher {
  position: relative;
  left: 0;
  height: 100%;
}

.mp-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  overflow-y: auto;
}

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  z-index: 1;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  opacity: 0;
}

.mp-pusher::after,
.mp-level::after {
  background: rgba(5, 13, 45, 0.3);
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.mp-level::after {
  z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  /* -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s; */
}

.mp-level.mp-level-overlay {
  cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level::before {
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 1;
}

.mp-pusher,
.mp-level {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(-40px, 0, 0);
  -moz-transform: translate3d(-40px, 0, 0);
  transform: translate3d(-40px, 0, 0);
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  box-shadow: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* cover */
.mp-cover .mp-level.mp-level-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

/* back button */
.mp-back {
  background: #e66c28;
  outline: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  display: block !important;
  font-size: 0.9rem;
  font-family: sans-serif;
  line-height: 1;
  padding: 0.7em 1em 0.7em 2.5em;
  position: relative;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.mp-menu ul li a.mp-back {
  padding: 1rem 2.5rem;
}

.mp-menu ul li a.mp-back:hover {
  color: #fff;
  background: #c35112;
  text-decoration: none;
  padding-left: 2em;
}

.mp-back::after {
  position: absolute;
  content: "‹";
  left: 20px;
  top: 6px;
  bottom: 0;
  font-size: 1.8rem;
  line-height: 1.1;
  opacity: 0.7;
}

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
  background: transparent;
  box-shadow: none;
  color: transparent;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */
.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
  display: none;
}

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
  display: block;
}

/* custom css */
.mp-menu ul li {
  width: 100%;
}

.mp-menu ul li:hover {
  background-color: rgb(237, 242, 247);
}

.mp-menu ul li a {
  padding: 0.5rem 1rem;
}

.mp-menu ul li a.arrow {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}

.menu li:hover,
.menu li:focus,
.menu li:active {
  background-color: transparent;
}

.menu li:hover .top-button,
.menu li:focus .top-button,
.menu li:active .top-button {
  background-color: #e66c28;
}

.menu li.active .dropdown {
  display: flex;
}

/* Start Single Section Menu */
.dropdown {
  display: none;
  min-height: 500px;
  /* max-height: 600px; */
  overflow-y: scroll;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #ffffff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  text-align: left;
  margin: 0;
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  /* animation-delay: .05s; */
}

.chevron-icon {
  background: url(../icons/chevron-wht.svg);
  background-repeat: no-repeat;
}

.dim {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  background: rgba(5, 13, 45, 0.3);
}

header .logo object {
  height: 50px;
  max-width: 450px;
}

#header-cta {
  margin: 0;
  text-align: center;
  max-width: 240px;
}

#mp-menu {
  display: none;
}

/* hover arrows */
a.arrow {
  padding-left: 4px;
  text-decoration: none;
  transition: margin 0.3s ease;
}

a.arrow:hover {
  margin-left: 1rem;
}

a.arrow:hover, .menu li.dp-open a:hover {
  color: #e66c28;
}

.arrow {
  width: 100%;
  position: relative;
  display: inline-block;
  transition: transform 0.25s ease;
}

.arrow::before {
  pointer-events: none;
  content: "→";
  opacity: 0;
  position: absolute;
  /* top: -2px; */
  left: 0;
  display: block;
  transform: translateX(-1.5em) translateZ(0);
  transition: transform 0.25s ease, opacity 0.25s ease;
}

a:hover .arrow::before, .arrow:hover::before {
  transform: translateX(-1em) translateZ(0);
  opacity: 1;
}

/* tablet Nav fix */
@media (max-width: 834px) {
  .mobile-expand {
    width: 100%;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #2c5282;
  }

  #main-nav, .desktop__hide, .tel svg {
    display: none;
  }

  .mobile__show, .mobile-trigger, #mp-menu {
    display: block;
  }
}
/**
* ----------------------------------------
* animation fade-in-top
* ----------------------------------------
*/
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.icon-amex:before {
  content: "\e900";
  color: #fff;
}

.icon-mastercard .path1:before {
  content: "\e901";
  color: rgb(255, 255, 255);
}

.icon-mastercard .path2:before {
  content: "\e902";
  margin-left: -1.67578125em;
  color: rgb(255, 95, 0);
}

.icon-mastercard .path3:before {
  content: "\e903";
  margin-left: -1.67578125em;
  color: rgb(235, 0, 27);
}

.icon-mastercard .path4:before {
  content: "\e904";
  margin-left: -1.67578125em;
  color: rgb(247, 158, 27);
}

.icon-visa .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}

.icon-visa .path2:before {
  content: "\e906";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}

.icon-visa .path3:before {
  content: "\e907";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}

.icon-visa .path4:before {
  content: "\e908";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}

.icon-visa .path5:before {
  content: "\e909";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}

.icon-arrow-down:before {
  content: "\e90e";
}

.icon-arrow-d:before {
  content: "\e90d";
}

.icon-arrow-left:before {
  content: "\e90f";
}

.icon-arrow-r-top:before {
  content: "\e910";
}

.icon-arrow-r:before {
  content: "\e911";
}

.icon-arrow-right:before {
  content: "\e912";
}

.icon-arrow-u:before {
  content: "\e97f";
}

.icon-facebook:before {
  content: "\e979";
}

.icon-instagram:before {
  content: "\e97a";
}

.icon-pinstrast:before {
  content: "\e97b";
}

.icon-tweeter:before {
  content: "\e97c";
}

.icon-whats-app:before {
  content: "\e97d";
}

.icon-youtube:before {
  content: "\e97e";
}

.icon-alert:before {
  content: "\e90a";
}

.icon-ambulance:before {
  content: "\e90b";
}

.icon-anchour:before {
  content: "\e90c";
}

.icon-base-camp-lux:before {
  content: "\e913";
}

.icon-base-camp:before {
  content: "\e914";
}

.icon-bear:before {
  content: "\e915";
}

.icon-bed:before {
  content: "\e916";
}

.icon-bird:before {
  content: "\e917";
}

.icon-bus:before {
  content: "\e918";
}

.icon-camping:before {
  content: "\e919";
}

.icon-chat:before {
  content: "\e91a";
}

.icon-chevron-down:before {
  content: "\e91b";
}

.icon-chevron-r:before {
  content: "\e91c";
}

.icon-chevron-right:before {
  content: "\e91d";
}

.icon-chevron2:before {
  content: "\e91e";
}

.icon-church:before {
  content: "\e91f";
}

.icon-climbing:before {
  content: "\e920";
}

.icon-close:before {
  content: "\e921";
}

.icon-confused:before {
  content: "\e922";
}

.icon-covid:before {
  content: "\e923";
}

.icon-cx-skiing:before {
  content: "\e924";
}

.icon-date:before {
  content: "\e925";
}

.icon-dog-sledding:before {
  content: "\e926";
}

.icon-dont-know:before {
  content: "\e927";
}

.icon-ecocamp:before {
  content: "\e928";
}

.icon-email-2:before {
  content: "\e929";
}

.icon-email:before {
  content: "\e92a";
}

.icon-eye:before {
  content: "\e92b";
}

.icon-fishing:before {
  content: "\e92c";
}

.icon-food:before {
  content: "\e92d";
}

.icon-glacier-trekking:before {
  content: "\e92e";
}

.icon-glass:before {
  content: "\e92f";
}

.icon-globe:before {
  content: "\e930";
}

.icon-guide:before {
  content: "\e931";
}

.icon-hand:before {
  content: "\e932";
}

.icon-hauled-sled:before {
  content: "\e933";
}

.icon-history:before {
  content: "\e934";
}

.icon-horse-riding:before {
  content: "\e935";
}

.icon-hotel:before {
  content: "\e936";
}

.icon-ice:before {
  content: "\e937";
}

.icon-image .path1:before {
  content: "\e938";
  color: rgb(0, 0, 0);
}

.icon-image .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}

.icon-image .path3:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-image .path4:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-info:before {
  content: "\e93c";
}

.icon-kayaking:before {
  content: "\e93d";
}

.icon-light:before {
  content: "\e93e";
}

.icon-lock:before {
  content: "\e93f";
}

.icon-lux_hotel:before {
  content: "\e940";
}

.icon-map-marker:before {
  content: "\e941";
}

.icon-map:before {
  content: "\e942";
}

.icon-market:before {
  content: "\e943";
}

.icon-menu:before {
  content: "\e944";
}

.icon-mountain-biking:before {
  content: "\e945";
}

.icon-mountain-peak:before {
  content: "\e946";
}

.icon-mountaineering:before {
  content: "\e947";
}

.icon-multi:before {
  content: "\e948";
}

.icon-museum:before {
  content: "\e949";
}

.icon-paddle-boarding:before {
  content: "\e94a";
}

.icon-payment:before {
  content: "\e94b";
}

.icon-penguin:before {
  content: "\e94c";
}

.icon-phone:before {
  content: "\e94d";
}

.icon-photography:before {
  content: "\e94e";
}

.icon-plain-2:before {
  content: "\e94f";
}

.icon-plain:before {
  content: "\e950";
}

.icon-play .path1:before {
  content: "\e951";
  color: rgb(0, 0, 0);
  opacity: 0.6;
}

.icon-play .path2:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-play .path3:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-price:before {
  content: "\e954";
}

.icon-puma:before {
  content: "\e955";
}

.icon-question-2:before {
  content: "\e956";
}

.icon-rafting:before {
  content: "\e957";
}

.icon-refugio:before {
  content: "\e958";
}

.icon-road-trip:before {
  content: "\e959";
}

.icon-sailing:before {
  content: "\e95a";
}

.icon-science:before {
  content: "\e95b";
}

.icon-scuba:before {
  content: "\e95c";
}

.icon-seal:before {
  content: "\e95d";
}

.icon-search:before {
  content: "\e95e";
}

.icon-sextant:before {
  content: "\e95f";
}

.icon-ship-2:before {
  content: "\e960";
}

.icon-ship-lg:before {
  content: "\e961";
}

.icon-ship-md:before {
  content: "\e962";
}

.icon-ship:before {
  content: "\e963";
}

.icon-sign:before {
  content: "\e964";
}

.icon-skiing:before {
  content: "\e965";
}

.icon-snorkelling:before {
  content: "\e966";
}

.icon-snow-shoeing:before {
  content: "\e967";
}

.icon-snow:before {
  content: "\e968";
}

.icon-snowmobiling:before {
  content: "\e969";
}

.icon-speach:before {
  content: "\e96a";
}

.icon-star-full:before {
  content: "\e96b";
}

.icon-star-half:before {
  content: "\e96c";
}

.icon-star:before {
  content: "\e96d";
}

.icon-suitcase:before {
  content: "\e96e";
}

.icon-swoop:before {
  content: "\e96f";
}

.icon-team:before {
  content: "\e970";
}

.icon-thanks:before {
  content: "\e971";
}

.icon-trekking:before {
  content: "\e972";
}

.icon-urgent:before {
  content: "\e973";
}

.icon-user:before {
  content: "\e974";
}

.icon-user2:before {
  content: "\e975";
}

.icon-vineyard:before {
  content: "\e976";
}

.icon-wildlife:before {
  content: "\e977";
}

.icon-zodiac_excursions:before {
  content: "\e978";
}

.trip-comparison .trip-markup ul {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  padding-top: 0.5rem;
  padding-left: 1rem;
  font-size: 0.9rem;
  line-height: 1.3;
}
.trip-comparison .trip-markup ul > li {
  padding-bottom: 0.5rem;
}
.trip-comparison .trip-markup p {
  font-size: 0.9rem;
  line-height: 1.3;
}
.trip-comparison .trip-markup b, .trip-comparison .trip-markup strong {
  font-family: inherit;
  font-weight: normal;
}
.trip-comparison .t-and-c > p {
  font-size: 0.95rem;
}
.trip-comparison .text-green-500 {
  color: #48bb78;
}
.trip-comparison .trip-addon:hover > img {
  opacity: 1;
}
.trip-comparison .react-tooltip {
  max-width: 500px;
}
@media (min-width: 1040px) {
  .trip-comparison div.flex-shrink-0.w-3\/12.mx-1 {
    min-width: 20rem;
  }
}

.transition-height {
  transition: height 0.5s;
}

.fix-content p {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

#compare-table {
  min-width: 800px;
}
@media (min-width: 1024px) {
  #compare-table {
    min-width: 900px;
  }
}
@media (min-width: 768px) {
  #compare-table {
    min-width: 1000px;
  }
}

#mfPreviewBar {
  display: none !important;
}

/*
------------------------------------------------------------------------------------ HTML / BODY

------------------------------------------------------------------------------------------------
*/
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "swoopicon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-weight: 300;
  font-size: 1rem;
  background-color: #ffffff;
  color: #454545;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
input,
textarea,
select,
button.btn-secondary,
.btn.btn-secondary,
button.btn-anchor,
.btn.btn-anchor,
button.btn-text,
.btn.btn-text,
.map h4,
.map p,
.chosen-container-multi .chosen-choices li.search-field input[type=text],
.btn.icon-menu,
.qtip-content,
.sw-body-font,
.font-serif {
  font-family: "PlantinMTStd-Regular", Georgia, serif;
}

.btn-logout {
  margin-right: 0;
}

i,
em,
blockquote .quote-content,
.banner-credit {
  font-family: Georgia, serif;
  font-style: italic;
}

b i,
strong i,
b em,
strong em,
i b,
i strong,
em b,
em strong {
  font-family: "PlantinMTStd-BoldItalic", Georgia, serif;
}

b, strong, .quote-name, .fact-list > li:before,
.qtip-title, .ship-facts-wrapper .trip-data-table .data-table-title p,
.customer-feedback-intro-question,
.customer-feedback-form .customer-feedback-radio-grid .control-label {
  font-family: "PlantinMTStd-Bold", Georgia, serif;
  font-weight: normal;
}

button,
.btn,
.footer-contact-links,
.header-contact .tel,
.header-login-info,
.summary-card-data,
.trips-table,
.trips-table strong,
.trips-table b,
.trips-table .btn.btn-text,
.add-ons-table,
.add-ons-table strong,
.add-ons-table b,
.add-ons-table .btn.btn-text,
.trip-banner-days,
.trip-banner-price,
.currency-pick,
.content-tel,
.nav-pills,
.customer-feedback-intro-list li:before,
.customer-feedback-form.form-horizontal .rating label,
.customer-feedback-form.form-horizontal .rating-container p,
.customer-feedback-intro-grid h2 span::before,
.avatar-row .avatar-title,
.icon-wishlist,
.font-sans {
  font-family: "Montserrat", Verdana, sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h3,
h4,
h5,
h6,
.sw-title-font {
  font-family: "ClarendonBT-Light", Georgia, serif;
  font-weight: normal;
  font-style: normal;
}

.font-bold {
  font-weight: 700 !important;
}

/*
---------------------------------------------------------------------------------- TEXT DEFAULTS

------------------------------------------------------------------------------------------------
*/
p {
  margin: 0 0 22px 0;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #e66c28;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  /*border-bottom: 1px solid $link-primary;*/
  word-wrap: break-word;
}

a:hover,
a.active {
  text-decoration: underline;
}

a:focus {
  outline: none;
}

h1,
h2,
h3,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #272727;
  margin: 0 0 27px 0;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  line-height: 1.2;
  letter-spacing: -1px;
}

h1 a,
h2 a,
h3 a {
  text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover {
  text-decoration: none;
}

h1 {
  font-size: 1.9rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.4rem;
}

hr {
  border: 0;
  height: 1px;
  background: #272727;
  margin-bottom: 1.5rem;
}

p.larger,
.larger p {
  font-size: 1rem;
  margin-bottom: 26px;
}
@media screen and (min-width: 833px) {
  p.larger,
.larger p {
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}

p.smaller,
.smaller p {
  font-size: 0.875rem;
}
@media screen and (min-width: 833px) {
  p.smaller,
.smaller p {
    font-size: 1rem;
  }
}

p.larger-default {
  margin-bottom: 22px;
}
@media screen and (min-width: 45em) {
  p.larger-default {
    margin-bottom: 22px;
  }
}

ul {
  list-style-type: disc;
}

ol {
  list-style: decimal;
}

.trip-summary > ul, .trip-summary ol, .content-block-content-inner > ul, .content-block-content-inner ol, .swoop-advice-content > ul, .swoop-advice-content ol, .trips-table-inclusion-list > ul, .trips-table-inclusion-list ol {
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 22px;
  line-height: 1.5;
}
.trip-summary > ul, .content-block-content-inner > ul, .swoop-advice-content > ul, .trips-table-inclusion-list > ul {
  margin-left: 1.2rem;
}
.trip-summary > ul li, .content-block-content-inner > ul li, .swoop-advice-content > ul li, .trips-table-inclusion-list > ul li {
  padding-bottom: 8px;
}
.trip-summary > ol, .content-block-content-inner > ol, .swoop-advice-content > ol, .trips-table-inclusion-list > ol {
  margin-left: 1.5rem;
}
.trip-summary > ol li, .content-block-content-inner > ol li, .swoop-advice-content > ol li, .trips-table-inclusion-list > ol li {
  padding-bottom: 1rem;
}
.trip-summary > ol li::marker, .content-block-content-inner > ol li::marker, .swoop-advice-content > ol li::marker, .trips-table-inclusion-list > ol li::marker {
  font-weight: bold;
}
.trip-summary li, .content-block-content-inner li, .swoop-advice-content li, .trips-table-inclusion-list li {
  margin: 0;
}

/*
----------------------------------------------------------------------------------------- LAYOUT

------------------------------------------------------------------------------------------------
*/
.row {
  padding: 0 12px;
}
.row:after {
  clear: both;
  content: "";
  display: table;
}

.row .row {
  padding-left: 0;
  padding-right: 0;
}

section,
.section {
  padding: 3rem 0;
}
@media screen and (max-width: 45em) {
  section,
.section {
    padding: 2rem 0;
  }
}

.row-centered {
  text-align: center;
}

/*
----------------------------------------------------------------------------------------- NEW FOOTER

------------------------------------------------------------------------------------------------
*/
section.footer-top {
  background-color: #041E42;
}

section.footer-mid {
  background-color: #0E2646;
}

section.footer-bottom {
  background-color: #1D3455;
  font-size: 0.8rem;
  padding: 1.3em 0;
}

.footer-btn {
  border: none;
  background: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 0 0 5px;
  letter-spacing: 1px;
  outline: none;
  color: #fff;
  position: relative;
}

.footer-btn:before {
  position: relative;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  height: 100%;
  line-height: 1.4;
  color: #fff;
  left: 70%;
  opacity: 0;
  top: 2px;
}

.footer-btn:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.footer-btn:hover {
  margin-left: 0;
  color: #ed8936;
}

.footer-btn:hover:before {
  left: calc(100% + 5px);
  opacity: 1;
}

.arrow-right:before {
  content: "ᐅ";
  color: inherit;
}

footer .form-control, #newsletter-name, #newsletter-email {
  font-size: 16px;
}

/* media query */
/*
----------------------------------------------- BACKGROUND COLOUR MODIFIERS / MORE CONTENT ARROW

------------------------------------------------------------------------------------------------
*/
.more-content {
  position: relative;
}

.more-content::before {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  background-color: #041e42;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border-radius: 40px;
}

.more-content::after {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -30px;
  margin-top: -33px;
  content: "";
  background-repeat: no-repeat;
  opacity: 0.7;
}

.more-content::before,
.more-content::after {
  display: none;
}

.bg-primary .row-title h1,
.bg-primary .row-title h2,
.bg-swoop .row-title h1,
.bg-swoop .row-title h2,
.bg-swoop-secondary .row-title h1,
.bg-swoop-secondary .row-title h2,
.bg-press .row-title h1,
.bg-press .row-title h2,
.bg-customers .row-title h1,
.bg-customers .row-title h2,
.bg-partners .row-title h1,
.bg-partners .row-title h2 {
  color: #ffffff;
}

.bg-primary,
.review-summary.bg-primary:before,
.bg-primary.more-content:before {
  background-color: #e66c28;
  color: #ffffff;
}

.bg-grey,
.bg-grey.more-content:before {
  background-color: #e8eaec;
}

.main .bg-grey:nth-child(even),
.main .bg-grey:nth-child(even).more-content:before {
  background-color: #d1d5d9;
}

.bg-swoop,
.review-summary.bg-swoop:before,
.bg-swoop.more-content:before {
  background-color: #041e42;
  color: #ffffff;
}

.bg-swoop-secondary,
.review-summary.bg-swoop-secondary:before,
.bg-swoop-secondary.more-content:before {
  background-color: #1d3455;
  color: #ffffff;
}

.bg-customers,
.review-summary.bg-customers:before,
.bg-customers.more-content:before {
  background-color: #48a9c5;
  color: #ffffff;
}

.bg-press,
.review-summary.bg-press:before,
.bg-press.more-content:before {
  background-color: #73cdd2;
  color: #ffffff;
}

.bg-partners,
.review-summary.bg-partners:before,
.bg-partners.more-content:before {
  background-color: #2e5665;
  color: #ffffff;
}

.bg-partners.more-content:after, .bg-press.more-content:after, .bg-customers.more-content:after, .bg-swoop-secondary.more-content:after, .bg-swoop.more-content:after, .bg-primary.more-content:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpolygon style='fill:%23ffffff' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3Cpolygon style='fill:%23ffffff' points='30,32 38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 '/%3E%3Cpath style='fill:%23ffffff' d='M30,10c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S41,10,30,10z M30,46.5c-9.1,0-16.5-7.4-16.5-16.5 S20.9,13.5,30,13.5S46.5,20.9,46.5,30S39.1,46.5,30,46.5z'/%3E%3Cpolygon style='fill:%23ffffff' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3C/svg%3E%0A");
}

.bg-grey.more-content:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpolygon style='fill:%23191919' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3Cpolygon style='fill:%23191919' points='30,32 38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 '/%3E%3Cpath style='fill:%23191919' d='M30,10c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S41,10,30,10z M30,46.5c-9.1,0-16.5-7.4-16.5-16.5 S20.9,13.5,30,13.5S46.5,20.9,46.5,30S39.1,46.5,30,46.5z'/%3E%3Cpolygon style='fill:%23191919' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3C/svg%3E%0A");
}

/*
-------------------------------------------------------------------------------- IMAGE GALLERIES

------------------------------------------------------------------------------------------------
*/
.gallery-wrapper {
  padding-top: 56px;
  padding-bottom: 1px;
}

/*
------------------------------------------------------------------------------------ VIDEO BLOCK

------------------------------------------------------------------------------------------------
*/
.video-container {
  max-width: 840px;
  margin: auto;
  margin-bottom: 1.5em;
}

/*
------------------------------------------------------------------------------- REVIEW SUMMARIES

------------------------------------------------------------------------------------------------
*/
.swoop-says-wrapper .review-summary {
  padding: 2rem 0 1.5rem;
}
.swoop-says-wrapper .review-summary:after {
  clear: both;
  content: "";
  display: table;
}

.review-summary {
  background-size: cover;
  position: relative;
  color: #ffffff;
  text-align: center;
  padding: 3rem 0 4rem;
}
.review-summary .bg-hover {
  background-color: rgba(29, 52, 85, 0.2);
  transition: background-color 400ms linear;
}
.review-summary .bg-hover:hover {
  background-color: rgba(29, 52, 85, 0.9);
}

.review-summary:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.88;
}

.avatar-row-small .avatar-img {
  max-width: 100px;
}

.avatar-row-xsmall .avatar-img {
  max-width: 80px;
}

.avatar-img {
  max-width: 100px;
  overflow: hidden;
  margin: 0 auto 22px auto;
  position: relative;
}

.avatar-img img,
.img-circle {
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  display: block;
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.15);
}

.review-summary .cta .btn {
  margin: 0;
}

.review-summary.bg-partners:before,
.review-summary.bg-swoop:before {
  opacity: 0.94;
}

.review-summary-split {
  position: relative;
  border-top: 1px solid #ffffff;
  padding-top: 27px;
}

.review-summary-split .quote:first-child {
  margin-bottom: 27px;
}

.review-summary-split .quote:first-child + div {
  border-top: 1px solid #ffffff;
  padding-top: 27px;
}

.review-summary .carousel.flickity-enabled {
  margin-bottom: 78px;
}

/*
----------------------------------------------------------------------------------------- QUOTES

------------------------------------------------------------------------------------------------
*/
.quote.quote-marks {
  max-width: 1024px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.quote-marks .quote-content {
  display: block;
  position: relative;
  min-height: 48px;
}

@media screen and (min-width: 45em) {
  .quote-marks .quote-content::before,
.quote-marks .quote-content::after {
    width: 64px;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23ffffff' d='M26.7,19c-1.9,1-3.3,1.9-4.4,2.8c-1.1,0.9-2,1.8-2.6,2.6s-1,1.6-1.1,2.4c-0.2,0.7-0.2,1.4-0.2,2.1c0,1.2,0.5,1.8,1.6,1.8 c4.7,0,7,2.2,7,6.6c0,2.5-0.7,4.4-2.1,5.7c-1.4,1.3-3.3,2-5.8,2c-3,0-5.3-0.9-6.9-2.7c-1.6-1.8-2.3-4.3-2.3-7.8 c0-4.5,1.2-8.3,3.8-11.3c2.5-3,6.1-5.7,10.8-8.1L26.7,19z M49.8,19c-1.9,1-3.4,1.9-4.5,2.8c-1.1,0.9-2,1.8-2.5,2.6 c-0.6,0.8-1,1.6-1.2,2.4c-0.2,0.7-0.2,1.4-0.2,2.1c0,1.2,0.5,1.8,1.6,1.8c4.7,0,7,2.2,7,6.6c0,2.5-0.7,4.4-2.1,5.7 c-1.4,1.3-3.3,2-5.8,2c-3,0-5.3-0.9-6.9-2.7c-1.6-1.8-2.4-4.3-2.4-7.8c0-4.5,1.3-8.3,3.8-11.3s6.2-5.7,10.9-8.1L49.8,19z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    opacity: 0.8;
  }

  .quote-marks .quote-content::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23ffffff' d='M33.2,41.1c1.9-1,3.3-1.9,4.4-2.8c1.1-0.9,2-1.8,2.6-2.6c0.6-0.8,1-1.6,1.1-2.4c0.2-0.7,0.2-1.4,0.2-2.1 c0-1.2-0.5-1.8-1.6-1.8c-4.7,0-7-2.2-7-6.6c0-2.5,0.7-4.4,2.1-5.7c1.4-1.3,3.3-2,5.8-2c3,0,5.3,0.9,6.9,2.7 c1.6,1.8,2.3,4.3,2.3,7.8c0,4.5-1.2,8.3-3.8,11.3c-2.5,3-6.1,5.7-10.8,8.1L33.2,41.1z M10.1,41.1c1.9-1,3.4-1.9,4.5-2.8 s2-1.8,2.5-2.6c0.6-0.8,1-1.6,1.2-2.4c0.2-0.7,0.2-1.4,0.2-2.1c0-1.2-0.5-1.8-1.6-1.8c-4.7,0-7-2.2-7-6.6c0-2.5,0.7-4.4,2.1-5.7 c1.4-1.3,3.3-2,5.8-2c3,0,5.3,0.9,6.9,2.7c1.6,1.8,2.4,4.3,2.4,7.8c0,4.5-1.3,8.3-3.8,11.3c-2.5,3-6.2,5.7-10.9,8.1L10.1,41.1z'/%3E%3C/svg%3E%0A");
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
  }
}
.quote-content,
.quote-desc {
  text-align: center;
}

.quote p {
  padding: 0 10px;
}

.quote-content p {
  font-size: 1.2em;
}

.quote-desc {
  margin-bottom: 22px;
}

.quote-desc p {
  margin-bottom: 0;
}

/*
------------------------------------------------------------------------------------- TRIP CARDS

------------------------------------------------------------------------------------------------
*/
.currency-pick a.active,
.currency-pick a.active:hover {
  color: #e66c28;
  border: 1px solid #e66c28;
  background-color: #fff;
  text-decoration: none;
}

/*
------------------------------------------------------------------------------------- ANCHOR ROW

------------------------------------------------------------------------------------------------
*/
/*
------------------------------------------------------------------------------------- PAGE INTRO

------------------------------------------------------------------------------------------------
*/
.page-title {
  padding-top: 0;
  text-align: center;
  border-bottom: 1px solid #272727;
}
.page-title:after {
  clear: both;
  content: "";
  display: table;
}

.page-title h2 {
  border-top: 4px solid #272727;
  padding-top: 22px;
  margin-bottom: 24px;
}

.page-title-collapse {
  border-bottom: none;
}

.page-intro-wrapper {
  padding-bottom: 0;
}
.page-intro-wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.page-intro-content {
  padding-top: 35px;
}

.page-intro-content p {
  font-size: 1rem;
  margin-bottom: 26px;
}
@media screen and (min-width: 833px) {
  .page-intro-content p {
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}

.page-intro-with-review .page-intro-content {
  text-align: left;
}

.page-intro-quote {
  padding-top: 35px;
}

/*
--------------------------------------------------------------------------- GUIDEBOOK NAVIGATION

------------------------------------------------------------------------------------------------
*/
/* Modifier */
/*
--------------------------------------------------------------------------------- CONTENT BLOCKS

------------------------------------------------------------------------------------------------
*/
div.content-block {
  margin-bottom: 20px;
}

.content-block .content-block-heading h2 {
  padding-top: 25px;
  padding-bottom: 22px;
  margin-bottom: 40px;
  border-top: 4px solid #272727;
  border-bottom: 1px solid #272727;
}

.content-block.guide-info-block .content-block-heading h3 {
  padding-top: 22px;
  padding-bottom: 20px;
  border-top: 4px solid #272727;
  border-bottom: 1px solid #272727;
}

.content-block.bl-close {
  padding-top: 0;
}

.heading-centered {
  text-align: center;
}

.lightbox {
  display: block;
}

/*
------------------------------------------------------------------------------------- DEAL BADGE

------------------------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------------- PAGE CTA

------------------------------------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------------------------------- FAQ

------------------------------------------------------------------------------------------------
*/
.faq-intro p {
  font-size: 1rem;
  margin-bottom: 26px;
}
@media screen and (min-width: 833px) {
  .faq-intro p {
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}

.faq-list {
  max-width: 740px;
  margin: 0 auto;
}

.faq-heading {
  border-top: 3px solid #272727;
}

.faq-heading::before {
  position: absolute;
  content: "ᐅ";
  font-size: 1rem;
  top: 18px;
  left: 10px;
  color: #e66c28;
}
@media only screen and (min-width: 640px) {
  .faq-heading::before {
    top: 19px;
  }
}

.faq-heading a {
  display: block;
  padding: 14px 0 14px 40px;
  color: #272727;
  position: relative;
}
@media only screen and (min-width: 640px) {
  .faq-heading a {
    padding: 14px 0 14px 50px;
  }
}

.faq-heading a:hover {
  color: #e66c28;
}

.faq-content {
  transition: height 0.5s ease-out;
  height: 0;
  overflow: hidden;
}

.faq-content-inner {
  border-top: 1px solid #272727;
  padding: 20px 20px 0 20px;
}
.faq-content-inner p:last-child {
  margin-bottom: 2rem !important;
}

.faq-content-inner ul {
  padding: 10px 16px 22px 16px;
}

.faq-open .faq-heading:before {
  content: "ᐁ";
  top: 15px;
  left: 8px;
}

/*
-------------------------------------------------------------------------------------- FACT LIST

------------------------------------------------------------------------------------------------
*/
.about-swoop-intro {
  max-width: 930px;
  margin: 0 auto;
  text-align: center;
}

.about-swoop-intro p {
  font-size: 1rem;
  margin-bottom: 26px;
}
@media screen and (min-width: 833px) {
  .about-swoop-intro p {
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}

.fact-list > ol {
  background-color: #ffffff;
  color: #454545;
  max-width: 740px;
  margin: 0 auto;
  counter-reset: li;
  padding-left: 0;
}

.fact-list > ol > li {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 1rem 2rem 1rem 3.2rem;
}

.fact-list > ol > li:before {
  content: counter(li) ")";
  counter-increment: li;
  position: absolute;
  top: 0.8rem;
  left: 1.4rem;
}

.fact-list ol p {
  font-size: 1em;
  margin-bottom: 0;
  line-height: 1.2;
}

.fact-list li:nth-child(even) {
  background-color: #e8eaec;
}

/*
----------------------------------------------------------------------------------- SWOOP ADVICE

------------------------------------------------------------------------------------------------
*/
.swoop-advice-summary {
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
  padding-top: 36px;
}

.swoop-advice-summary p {
  font-size: 1rem;
  margin-bottom: 26px;
  margin-bottom: 0;
}
@media screen and (min-width: 833px) {
  .swoop-advice-summary p {
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}

.swoop-advice-content {
  max-width: 740px;
  margin: 0 auto;
  padding-top: 36px;
}

/*
----------------------------------------------------------------------------------- PAYMENT PAGE

------------------------------------------------------------------------------------------------
*/
/* ====== Zoom effect ====== */
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transform: scale(0.8);
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
---------------------------------------------------------------------------------------- IE9 Fixes

------------------------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------- ENQUIRY THANKS POPUP

------------------------------------------------------------------------------------------------
*/
.adv-thanks {
  text-align: center;
}

.enquiry-thanks-popup,
.white-popup.enquiry-thanks-popup {
  background-color: #d1d5d9;
}

.enquiry-thanks-popup .modal-header h2 {
  margin-bottom: 12px;
}

.enquiry-thanks-popup .modal-header p {
  font-size: 1em;
}

.enquiry-popup-form-intro {
  font-size: 2.6em;
  max-width: 490px;
  margin-left: auto;
  margin-right: auto;
}

.enquiry-popup-form-phone-intro {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.enquiry-popup-btn {
  color: #454545;
  background-color: #b4bac6;
  font-size: 18px;
  line-height: 1.55555556;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  padding: 8px 4px;
  margin: 0 auto 15px auto;
  max-width: 370px;
  transition: background-color 100ms linear, color 100ms linear, border-color 100ms linear;
}

.enquiry-popup-btn:hover,
.enquiry-popup-btn.active {
  text-decoration: none;
  color: #ffffff;
  background-color: #041e42;
}

.js-enquiry-popup-radios .radio {
  display: none;
}

.enquiry-popup-form-fields {
  max-width: 370px;
  margin: 0 auto;
}

.enquiry-popup-form-fields label {
  font-size: 1.1em;
}

.enquiry-popup-form-fields input {
  text-align: center;
}

.white-popup.enquiry-typeform-popup {
  padding: 0;
}

.white-popup.enquiry-typeform-popup .mfp-close {
  right: 20px;
}

.mfp-close-btn-in .white-popup.enquiry-typeform-popup .mfp-close {
  color: #fff;
}

.file-icon {
  text-indent: 20px;
  display: inline-block;
  line-height: 28px;
  position: relative;
}

.file-icon:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  height: 28px;
  background: url("../img/swoop_doc_icon_sprite.png") 0 0 no-repeat;
}
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
  .file-icon:before {
    background-image: url("../img/swoop_doc_icon_sprite.png");
    background-size: 280px 80px;
  }
}

.icon-doc:before,
.icon-docx:before {
  background-position: -35px 0;
}

.icon-pdf {
  padding-left: 15px;
}

.icon-pdf:before {
  background-position: -70px 0;
}

.icon-ppt:before {
  background-position: -105px 0;
}

.icon-xls:before,
.icon-xlsx:before,
.icon-csv:before {
  background-position: -140px 0;
}

.icon-link:before {
  background-position: -175px 0;
}

/* DL Table */
/*
----------------------------------------------------------------------------------------- ENQUIRY FORM

------------------------------------------------------------------------------------------------
*/
/* The checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: solid 2px #cdcfcf;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  border-color: #575757;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #ed8936;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.guidebook input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.guidebook input[type=checkbox]:active ~ label, .guidebook input[type=checkbox]:checked ~ label {
  border-color: #ed8936;
  background-color: #fff;
}

/* Customize the label (the container) */
.checkbox-label,
.icon-label {
  display: block;
  position: relative;
  padding-left: 33px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 1.1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon-label {
  margin-bottom: 73px;
}

.icon {
  position: absolute;
  top: 4px;
  left: 11px;
  height: 60px;
  width: 60px;
}

.icon-grid {
  display: grid;
  grid-template-columns: 11.5% 11.5% 11.5% 11.5% 11.5% 11.5% 11.5% 11.5%;
  grid-column-gap: 10px;
  grid-template-rows: auto;
  grid-row-gap: 18px;
  font-size: small;
  text-align: center;
}

/* On mouse-over, add a #454545 background color */
.checkbox-label:hover input ~ .checkmark {
  background-color: #454545;
}

/* When the checkbox is checked, add a #454545 background */
.checkbox-label input:checked ~ .checkmark {
  background-color: #454545;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-label .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.wildlife-select [type=checkbox]:checked,
.wildlife-select [type=checkbox]:not(:checked),
[type=radio]:checked,
[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.wildlife-select [type=checkbox]:checked + label,
.wildlife-select [type=checkbox]:not(:checked) + label {
  position: relative;
  cursor: pointer;
}

[type=radio]:checked + label,
[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

[type=radio]:checked + label:before,
[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #D9E3F0;
  border-radius: 100%;
  background: #fff;
}

[type=radio]:checked + label:after,
[type=radio]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #FD8E50;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.wildlife-select [type=checkbox]:checked + label {
  border-color: #ed8936;
}

.wildlife-select [type=checkbox]:checked + label > img {
  opacity: 0.5;
}

[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.form-email-number {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.call-time {
  width: 50%;
}

.first-sur {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.enquiry-form-title {
  font-size: 1.3em;
  color: white;
}

.enquiry-form-header {
  background-color: rgb(35, 54, 82);
  text-align: center;
  padding: 42px 0px 17px 0px;
}

.form-container-inner {
  display: flex;
}

.enquiry-form-right {
  padding: 30px;
  display: flex;
  width: 360px;
  border-left: 1px solid grey;
  margin-left: 30px;
}

.enquiry-dates-flight {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7%;
}

.enquiry-radio-label {
  font-size: 1.1em;
  margin-bottom: 4%;
  text-align: right;
}

.enquiry-radios {
  font-family: "PlantinMTStd-Regular", Times, serif;
  margin-left: 68px;
}

.enquiry-form-area-main {
  font-weight: 600;
  color: hsla(0deg, 0%, 0%, 0.75);
}

.enquiry-form-loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #e66c28;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: 1px 75px 1px 75px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.post-form-page {
  display: grid;
  grid-template-columns: 55%;
  justify-content: center;
}

@media screen and (max-width: 648px) {
  .form-email-number {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }

  .call-time {
    width: 100%;
  }

  .first-sur {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0px;
  }

  .icon-grid {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }

  .icon {
    left: 16px;
  }

  .enquiry-dates-flight {
    display: block;
  }

  .enquiry-radios {
    margin-left: 0;
  }

  .enquiry-radio-label {
    text-align: inherit;
  }

  .post-form-page {
    grid-template-columns: 83%;
  }

  .enquiry-form-right {
    border-left: none;
    margin: 0 auto;
  }

  .form-container-inner {
    flex-direction: column;
  }
}
@media screen and (max-width: 450px) {
  .enquiry-form-title {
    font-size: 1.1em;
  }

  .icon-grid {
    grid-template-columns: 23% 23% 23% 23%;
  }

  .icon {
    left: 11px;
  }
}
.file-upload-input .form-control {
  width: 100%;
  float: none;
  margin-left: -1px;
  border-right: 1px solid #cecfcf;
}

.async-upload {
  margin-bottom: 10px;
}
.async-upload:after {
  clear: both;
  content: "";
  display: table;
}

/* Progress bar */
.progress {
  background-color: #e8eaec;
}

.progress-bar {
  background-color: #1ab394;
}

.progress-small,
.progress-small .progress-bar {
  height: 10px;
}

.progress-small,
.progress-mini {
  margin-top: 5px;
}

.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0;
}

.content-block .content-block-img {
  margin-bottom: 3%;
}

/* --------------------------------------------------------------------------------- BREAK - PHONE */
/* --------------------------------------------------------------------------------- BREAK - TABLET */
@media screen and (min-width: 30em) {
  /* ---------------------- BANNERS / CAROUSELS */
  .hero-img .row {
    position: relative;
  }

  .hero-caption {
    padding-top: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
  }
}
@media screen and (min-width: 833px) {
  /* ---------------------- TEXT DEFAULTS */
  h1 {
    font-size: 3.7rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  p,
ul,
ol,
dl {
    font-size: 1.18rem;
  }

  .page-title {
    padding-top: 3rem;
  }

  .quote-content p, .press-quote-card .quote-content p {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 45em) {
  /* ---------------------- LAYOUT */
  .row {
    max-width: 1184px;
    margin: 0 auto;
  }

  .row,
.header .row {
    padding: 0 32px;
  }

  .row .row {
    padding-left: 0;
    padding-right: 0;
  }

  .article-content {
    /*@include span(8 of 12);*/
    width: 66.6666%;
    float: left;
  }

  .side-bar {
    /*@include span(3 of 12 last);*/
    width: 33.3333%;
    float: left;
  }

  /* ---------------------- FOOTER */
  .footer-nav {
    clear: both;
  }

  .footer-accreditation img {
    max-height: 90px;
  }

  /* ---------------------- BANNERS / CAROUSELS */
  .banner + .more-content:before,
.banner + .more-content:after {
    display: block;
  }

  .hero-img,
.hero-img .row {
    min-height: 380px;
  }

  .quote p {
    padding: 0 100px;
  }

  .quote-no-avatar {
    padding-top: 80px;
  }

  .quote-marks .quote-content:before,
.quote-marks .quote-content:after {
    display: block;
  }

  .gallery-wrapper .row {
    max-width: 900px;
  }

  #vessel-wrapper.gallery-wrapper .row {
    max-width: 1180px;
  }

  .review-summary-inline {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 42px;
  }

  .row .review-summary-inline {
    padding-left: 32px;
    padding-right: 32px;
  }

  .review-summary-inline .quote:after {
    clear: both;
    content: "";
    display: table;
  }

  .review-summary-inline .quote p {
    margin-bottom: 10px;
  }

  .review-summary-inline .quote .quote-desc p {
    margin-bottom: 0;
  }

  .review-summary-inline .avatar-img {
    float: left;
    width: 90px;
    margin-bottom: 0;
  }

  .review-summary-inline blockquote {
    margin-left: 120px;
  }

  .review-summary-inline .quote-content,
.review-summary-inline .quote-desc {
    text-align: left;
  }

  .review-summary-inline .quote-desc {
    margin-bottom: 0;
  }

  .about-ship-wrapper .content-block-content ul {
    margin-bottom: 0;
  }

  .about-ship-wrapper .content-block-content li {
    padding-bottom: 6px;
  }

  /* ---------------------- BLOCKS */
  .page-intro-with-review .page-intro-content {
    width: 50%;
    float: left;
    padding-right: 32px;
  }

  .page-intro-with-review .page-intro-quote {
    width: 50%;
    float: left;
  }

  .page-intro-with-review .quote-inline {
    border-left: 1px solid #272727;
    padding-left: 32px;
  }

  .content-block:after {
    clear: both;
    content: "";
    display: table;
  }

  .content-block .content-block-img {
    width: 49%;
    float: left;
    padding: 0;
    margin: 0 2% 2% 0;
  }

  .content-block .content-block-content {
    width: 49%;
    float: right;
  }

  .content-block .content-block-heading {
    margin-bottom: 20px;
  }

  .content-block-heading > h2 {
    text-align: center;
  }

  /* ---------------------- BLOCK MODIFIERS */
  .content-block.bl-full-width .content-block-img,
.content-block.bl-full-width .content-block-content {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }

  .content-block.bl-full-width .content-block-content, .content-block.bl-full-width .content-block-heading.content-block-heading-with-sub > h3 {
    max-width: 740px;
    margin: 0 auto;
  }

  .content-block.bl-wrap .content-block-content {
    width: auto;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .content-block.bl-wrap .content-block-content ul {
    list-style-position: inside;
  }

  .content-block.bl-img-left.bl-wrap .content-block-img {
    margin-left: 0;
  }

  /* ---------------------- NAVIGATION ROW */
  .nav-row-card .summary-card-heading h3 {
    font-size: 2.6em;
  }

  /* ---------------------- TRIP CONTENT */
  .trip-data-tables:after {
    clear: both;
    content: "";
    display: table;
  }

  .trip-cta-a,
.trip-banner-days {
    text-align: right;
  }

  .trip-cta-b,
.trip-banner-price {
    text-align: left;
  }

  .trip-banner-days p {
    padding-right: 0;
  }

  .js.no-classlist .trip-cards-carousel .summary-card {
    display: block;
  }

  .js-trip-cards-carousel .summary-card {
    display: block;
  }

  .summary-card-data-container {
    width: 100%;
    float: left;
    margin-right: -180px;
  }

  .summary-card-data {
    margin-right: 180px;
  }

  .trip-cards-wrapper .summary-card .cta {
    float: right;
  }

  .nav-row-card .cta,
.trip-question-card .cta {
    float: none;
  }

  .map-key-item {
    width: 50%;
    float: left;
  }

  .quote-content p, .press-quote-card .quote-content p {
    font-size: 1.4rem;
  }

  /* ---------------------- ENQUIRY PAGE */
  .enquiry-trip-content-wrapper:after {
    clear: both;
    content: "";
    display: table;
  }

  /* ---------------------- ANCHOR ROW */
  /* ---------------------- PAGE CTA */
  .avatar-row {
    text-align: center;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }

  .avatar-row li {
    display: inline-block;
    margin: 0 16px;
    width: auto;
    float: none;
  }

  .avatar-img {
    max-width: 120px;
  }

  .avatar-row-xsmall li {
    margin: 0 8px;
    padding: 0;
  }

  /* ---------------------- GOOGLE MAP */
  .map-canvas {
    height: 640px;
  }

  .flex-box-test {
    font-size: 1em;
    list-style-type: none;
    margin: 0;
  }
  .flex-box-test:after {
    clear: both;
    content: "";
    display: table;
  }

  .flex-box-test li {
    background-color: red;
  }

  .flex-head {
    background-color: blue;
  }

  .flex-content {
    background-color: grey;
  }
}
@media screen and (min-width: 1024px) {
  .text-height {
    min-height: 6rem;
  }
}
.cta-about {
  display: none;
}

@media (min-width: 768px) {
  .cta-enquiry {
    display: none;
  }

  .cta-about {
    display: block;
  }
}
.floating-form .h-2 {
  height: auto;
}

.youtube-embed {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 0;
}

.youtube-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}

.youtube-embed img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}

.youtube-embed img:hover {
  -webkit-filter: brightness(75%);
}

.youtube-embed .play {
  height: 7rem;
  width: 7rem;
  left: 50%;
  top: 50%;
  margin-left: -3.5rem;
  margin-top: -3.5rem;
  position: absolute;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cstyle type='text/css'%3E .opt%7Bopacity:0.6;%7D .col-wht%7Bfill:%23FFFFFF;%7D %3C/style%3E%3Ccircle class='opt' cx='29.8' cy='29.2' r='19.3'/%3E%3Cpath class='col-wht' d='M28,34.8l7.7-5c0.4-0.3,0.4-0.9,0-1.2l-7.7-5c-0.6-0.4-1.4,0-1.4,0.6v10C26.6,34.8,27.4,35.2,28,34.8z'/%3E%3Cpath class='col-wht' d='M29.8,13.6c-8.6,0-15.6,7-15.6,15.6s7,15.6,15.6,15.6c8.6,0,15.6-7,15.6-15.6S38.4,13.6,29.8,13.6z M29.8,42.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9C42.6,36.4,36.9,42.1,29.8,42.1z'/%3E%3C/svg%3E") no-repeat;
  cursor: pointer;
}

/*
Lazy Load: avoid empty images to appear as broken
*/
/*
Lazy Load: Fixes the Firefox anomaly while images are loading
*/
figure img,
.img-fit {
  object-fit: cover;
  width: 100%;
}

figure:hover img,
article a:hover > img {
  opacity: 0.8;
  transition: opacity 0.5s;
  transition-timing-function: ease-out;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-0 {
  margin-right: 0px;
}

div.jumplink a[href$=hidden], a[data-ga-event*=hidden] {
  display: none;
}

.scroller {
  animation: arrow 4s infinite;
  -webkit-animation: arrow 4s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
    -webkit-transform: translatex(10px);
    transform: translatex(10px);
  }
  80% {
    opacity: 1;
    -webkit-transform: translatex(50px);
    transform: translatex(50px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translatex(50px);
    transform: translatex(50px);
  }
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
    -webkit-transform: translatex(10px);
    transform: translatex(10px);
  }
  80% {
    opacity: 1;
    -webkit-transform: translatex(50px);
    transform: translatex(50px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translatex(50px);
    transform: translatex(50px);
  }
}
.scroller {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}